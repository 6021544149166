// src/math.ts
var sum = (nums) => nums.reduce((accm, num) => accm + num, 0);
var avg = (nums) => {
  if (nums.length === 0) {
    return 0;
  }
  if (nums.length === 1) {
    return nums[0] ?? 0;
  }
  return sum(nums) / nums.length;
};
var pow = (exponent) => (base) => Math.pow(base, exponent);
var rms = (nums) => {
  if (!nums || !Array.isArray(nums) || !nums.length) {
    return 0;
  }
  if (nums.length === 1 && nums[0] !== void 0) {
    return Math.abs(nums[0]);
  }
  return Math.sqrt(sum(nums.map(pow(2))) / nums.length);
};
var round = (num) => num ? Math.round(num) : -Math.round(-num);

// src/utils.ts
var hasAudioContext = () => typeof AudioContext !== "undefined";
var hasCreateGain = (context) => typeof context.createGain !== "undefined";
var stopTrack = (track) => track.stop();
var stopStreamTracks = (stream) => stream?.getTracks().forEach(stopTrack);
var createMediaStreamAudioSourceNode = (context, options) => {
  try {
    const source = new MediaStreamAudioSourceNode(context, options);
    return source;
  } catch {
    return context.createMediaStreamSource(options.mediaStream);
  }
};
var createMediaElementSourceNode = (context, options) => {
  try {
    const source = new MediaElementAudioSourceNode(context, options);
    return source;
  } catch {
    return context.createMediaElementSource(options.mediaElement);
  }
};
var setAudioNodeOptions = (node, options) => {
  if (options?.channelCount) {
    node.channelCount = options.channelCount;
  }
  if (options?.channelCountMode) {
    node.channelCountMode = options.channelCountMode;
  }
  if (options?.channelInterpretation) {
    node.channelInterpretation = options.channelInterpretation;
  }
};
var createAnalyserNode = (audioContext, options) => {
  try {
    const analyser = new AnalyserNode(audioContext, options);
    return analyser;
  } catch {
    const analyser = audioContext.createAnalyser();
    options?.fftSize && (analyser.fftSize = options.fftSize);
    options?.maxDecibels && (analyser.maxDecibels = options.maxDecibels);
    options?.minDecibels && (analyser.minDecibels = options.minDecibels);
    options?.smoothingTimeConstant && (analyser.smoothingTimeConstant = options.smoothingTimeConstant);
    setAudioNodeOptions(analyser, options);
    return analyser;
  }
};
var createGainNode = (context, options) => {
  try {
    const volume = new GainNode(context, options);
    return volume;
  } catch {
    const volume = hasCreateGain(context) ? context.createGain() : context.createGainNode();
    if (options?.gain) {
      volume.gain.setValueAtTime(options.gain, context.currentTime);
    }
    setAudioNodeOptions(volume, options);
    return volume;
  }
};
var createMediaStreamAudioClone = (stream) => {
  try {
    const mediaStream = new MediaStream(
      stream.getAudioTracks().map((track) => track.clone())
    );
    return mediaStream;
  } catch {
    return stream.clone();
  }
};
var createMediaStreamAudioDestinationNode = (context, options) => {
  try {
    const destination = new MediaStreamAudioDestinationNode(
      context,
      options
    );
    return destination;
  } catch {
    const destination = context.createMediaStreamDestination();
    setAudioNodeOptions(destination, options);
    return destination;
  }
};
var createDelayNode = (context, options) => {
  try {
    const delay = new DelayNode(context, options);
    return delay;
  } catch {
    const delay = context.createDelay(options?.maxDelayTime);
    if (options?.delayTime !== void 0) {
      delay.delayTime.setValueAtTime(
        options?.delayTime,
        context.currentTime
      );
    }
    setAudioNodeOptions(delay, options);
    return delay;
  }
};
var createChannelSplitterNode = (context, options) => {
  try {
    const node = new ChannelSplitterNode(context, options);
    return node;
  } catch {
    const node = context.createChannelSplitter(options?.numberOfOutputs);
    setAudioNodeOptions(node, options);
    return node;
  }
};
var createChannelMergerNode = (context, options) => {
  try {
    const node = new ChannelMergerNode(context, options);
    return node;
  } catch {
    const node = context.createChannelMerger(options?.numberOfInputs);
    setAudioNodeOptions(node, options);
    return node;
  }
};
var muteToGain = (mute) => mute ? 0 : 1;
var calculateNextTimeout = (targetTime, startTime, endTime) => Math.max(targetTime - Math.max(endTime - startTime, 0), 0);
var createDelayedCallback = (callback, {
  setTimeout: setTimeout2 = window.setTimeout,
  clearTimeout = window.clearTimeout
} = {}) => {
  const props = {
    timeoutID: 0
  };
  const cancelTimeout = () => {
    if (props.timeoutID) {
      clearTimeout(props.timeoutID);
      props.timeoutID = 0;
    }
  };
  const delayedCallback = async (delayMs, ...params) => {
    const resolved = await new Promise((resolve) => {
      cancelTimeout();
      props.timeoutID = setTimeout2(() => {
        const result = callback(...params);
        if (result instanceof Promise) {
          result.then((resolved2) => resolve(resolved2)).catch((e) => {
            throw e;
          });
        } else {
          resolve(result);
        }
      }, delayMs);
      props.cancel = resolve;
    });
    return resolved;
  };
  const cancel = () => {
    cancelTimeout();
    props.cancel?.();
  };
  return [delayedCallback, cancel];
};
var rateToMs = (rate) => Math.ceil(1e3 / rate);
var createAsyncCallbackLoop = (callback, frameRate, {
  setTimeout: setTimeout2 = window.setTimeout,
  clearTimeout = window.clearTimeout,
  now = () => performance.now()
} = {}) => {
  const props = {
    frameRate,
    targetMs: rateToMs(frameRate),
    prevCalledMs: 0,
    timeoutID: 0,
    stopped: false
  };
  const [delayedCallback, cancel] = createDelayedCallback(callback, {
    setTimeout: setTimeout2,
    clearTimeout
  });
  const fork = async (...params) => {
    if (props.stopped) {
      return;
    }
    const currentMs = now();
    const nextMs = calculateNextTimeout(
      props.targetMs,
      props.prevCalledMs,
      currentMs
    );
    props.prevCalledMs = currentMs;
    await delayedCallback(nextMs, ...params);
    await fork(...params);
  };
  return {
    start: async (...params) => {
      props.prevCalledMs = now();
      props.stopped = false;
      await delayedCallback(0, ...params);
      void fork(...params);
    },
    stop: () => {
      props.stopped = true;
      cancel();
    },
    get frameRate() {
      return props.frameRate;
    },
    set frameRate(value) {
      props.frameRate = value;
      props.targetMs = rateToMs(value);
    }
  };
};
var DEFAULT_THROTTLE_MS = 3e3;
var throttleProcess = (callback, throttleMs = DEFAULT_THROTTLE_MS, clock = performance) => {
  let lastCall = 0;
  return (...params) => {
    const now = clock.now();
    if (now - lastCall >= throttleMs) {
      callback(...params);
      lastCall = now;
    }
  };
};
var subscribeVisibilityChangeEvent = (callback) => {
  const handleEvent = () => {
    callback(document.hidden).catch((error) => {
      throw error;
    });
  };
  document.addEventListener("visibilitychange", handleEvent);
  return () => {
    document.removeEventListener("visibilitychange", handleEvent);
  };
};

// src/process.ts
var SILENT_THRESHOLD = 1 / 32767;
var MONO_THRESHOLD = 1 / 65536;
var LOW_VOLUME_THRESHOLD = -60;
var CLIP_THRESHOLD = 0.98;
var VOICE_PROBABILITY_THRESHOLD = 0.3;
var CLIP_COUNT_THRESHOLD = 6;
var createAudioStats = (stats = {}, {
  silentThreshold,
  lowVolumeThreshold,
  clipCountThreshold
} = {}) => {
  return {
    peak: stats.peak ?? 0,
    maxRms: stats.maxRms ?? 0,
    maxClipCount: stats.maxClipCount ?? 0,
    sumSquare: stats.sumSquare ?? 0,
    sumLength: stats.sumLength ?? 0,
    get silent() {
      return isSilent([this.peak], silentThreshold);
    },
    get clipping() {
      return isClipping(this.maxClipCount, clipCountThreshold);
    },
    set clipping(value) {
      this.clipping = value;
    },
    get rms() {
      return this.sumLength && Math.sqrt(this.sumSquare / this.sumLength);
    },
    get lowVolume() {
      return this.rms === void 0 ? false : isLowVolume(this.rms, lowVolumeThreshold);
    }
  };
};
var fromByteToFloat = (value) => (value - 128) / 128;
var fromFloatToByte = (value) => round(value * 128 + 128);
var copyByteBufferToFloatBuffer = (bytes, floats) => {
  bytes.forEach((value, idx) => {
    floats[idx] = fromByteToFloat(value);
  });
};
var toDecibel = (gain) => 20 * Math.log10(Math.abs(gain));
var processAverageVolume = (data) => data.length ? rms(data) : 0;
var isSilent = (samples, threshold = SILENT_THRESHOLD) => samples.length === 0 || getFirstSample(samples) <= threshold && getLastSample(samples) <= threshold;
function getFirstSample(samples) {
  if (samples[0] !== void 0) {
    return Math.abs(samples[0]);
  }
  return 0;
}
function getLastSample(samples) {
  const last = samples[samples.length - 1];
  if (last) {
    return Math.abs(last);
  }
  return 0;
}
var isLowVolume = (gain, threshold = LOW_VOLUME_THRESHOLD) => toDecibel(gain) < threshold;
var isClipping = (clipCount, threshold = CLIP_COUNT_THRESHOLD) => clipCount > threshold;
var isMono = (channels, threshold = MONO_THRESHOLD) => {
  let sampleDiffCount = 0;
  if (channels.length < 2 || channels.filter((channel) => !isSilent(channel)).length < 2) {
    return true;
  }
  if (channels[0]?.length === channels[1]?.length) {
    channels[0]?.forEach((l, idx) => {
      const r = channels[1]?.[idx];
      if (r !== void 0 && Math.abs(l - r) > threshold) {
        sampleDiffCount++;
      }
    });
  } else {
    sampleDiffCount++;
  }
  return sampleDiffCount === 0;
};
var getAudioStats = ({
  samples,
  baseStats,
  clipThreshold = CLIP_THRESHOLD
}) => {
  let rms2 = 0;
  let clipCount = 0;
  let maxClipCount = 0;
  let peak = 0;
  const stats = baseStats || createAudioStats();
  samples.forEach((s) => {
    const absS = Math.abs(s);
    peak = Math.max(peak, absS);
    if (absS >= clipThreshold) {
      clipCount += 1;
      maxClipCount = Math.max(clipCount, maxClipCount);
    } else {
      clipCount = 0;
    }
    rms2 += absS * absS;
  });
  stats.peak = Math.max(stats.peak ?? 0, peak);
  stats.sumSquare += rms2;
  stats.sumLength += samples.length;
  rms2 = samples.length ? Math.sqrt(rms2 / samples.length) : 0;
  stats.maxRms = Math.max(stats.maxRms ?? 0, rms2);
  stats.maxClipCount = Math.max(maxClipCount, stats.maxClipCount ?? 0);
  return stats;
};
var isVoiceActivity = ({
  volumeThreshold = 0.05,
  VADTimeThreshold = 500,
  clock = performance
} = {}) => {
  let lastVADTime = 0;
  return (volume) => {
    if (volume >= volumeThreshold) {
      const now = clock.now();
      if (!lastVADTime) {
        lastVADTime = now;
        return false;
      }
      if (now - lastVADTime >= VADTimeThreshold) {
        return true;
      }
      return false;
    }
    if (lastVADTime) {
      lastVADTime = 0;
    }
    return false;
  };
};
var isEqualSize = (widthA, heightA, widthB, heightB) => widthA === widthB && heightA === heightB;
var fitDestinationSize = (sw, sh, dw, dh) => {
  if (!sw || !sh || !dw || !dh) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }
  if (isEqualSize(sw, sh, dw, dh)) {
    return { x: 0, y: 0, width: sw, height: sh };
  }
  const height = Math.floor(dw * (sh / sw));
  const y = Math.floor((dh - height) / 2);
  return { x: 0, y, width: dw, height };
};
var createVoiceDetectorFromTimeData = (options = {}) => {
  const isVoice = isVoiceActivity(options);
  return (timeData) => isVoice(rms(timeData));
};
var createVoiceDetectorFromProbability = (voiceThreshold = VOICE_PROBABILITY_THRESHOLD) => (probability) => probability >= voiceThreshold;
var createVADetector = (onDetected, shouldDetect, options) => (isVoice) => {
  const throttledTrigger = throttleProcess(
    onDetected,
    options?.throttleMs,
    options?.clock
  );
  const process = (data) => {
    if (!shouldDetect()) {
      return;
    }
    if (isVoice(data)) {
      throttledTrigger();
    }
  };
  return process;
};
var createAudioSignalDetector = (shouldDetect, onDetected) => (buffer, threshold) => {
  const props = { silent: false, lastCheck: false };
  return (samples) => {
    if (!shouldDetect()) {
      buffer.empty();
      props.silent = false;
      props.lastCheck = false;
      return;
    }
    if (buffer.enqueue(samples) >= buffer.maxSize) {
      props.lastCheck = props.silent;
      props.silent = buffer.dequeueAll().every((samples2) => isSilent(samples2, threshold));
      if (props.lastCheck !== props.silent) {
        onDetected(props.silent);
      }
    }
  };
};

// src/workletNodes.ts
var createDenoiseWorkletNode = (context, options) => {
  return new AudioWorkletNode(context, "denoise-processor", options);
};

// src/typeGuards.ts
var isAudioNode = (t) => {
  if (typeof t === "object" && t !== null) {
    return "connect" in t && "disconnect" in t;
  }
  return false;
};
var isAudioParam = (t) => {
  if (typeof t === "object" && t !== null) {
    return "setValueAtTime" in t;
  }
  return false;
};
var isAudioNodeInit = (t) => {
  if (typeof t === "object" && t !== null) {
    return "audioNode" in t && "create" in t && "release" in t;
  }
  return false;
};
var isAnalyzerNodeInit = (t) => {
  if (isAudioNodeInit(t) && t.audioNode) {
    return "getFloatTimeDomainData" in t.audioNode;
  }
  return false;
};

// src/audio.ts
var createAudioContext = (options) => {
  if (hasAudioContext()) {
    return new AudioContext(options);
  }
  return new window.webkitAudioContext(options);
};
function resumeAudioOnInterruption(audioContext) {
  audioContext.onstatechange = () => {
    if (audioContext.state === "interrupted") {
      void audioContext.resume();
    }
  };
}
var createGainWithMute = (context, muteInit = false) => {
  let mute = muteInit;
  const gain = createGainNode(context, {
    gain: muteToGain(mute),
    channelCountMode: "explicit"
  });
  const disconnect = gain.disconnect.bind(gain);
  const connect = gain.connect.bind(gain);
  return {
    get node() {
      return gain;
    },
    get mute() {
      return mute;
    },
    set mute(shouldMute) {
      mute = shouldMute;
      const time = context.currentTime;
      if (isFinite(time)) {
        gain.gain.setValueAtTime(muteToGain(shouldMute), time);
      } else {
        throw new Error("Set gain with non-finite time value");
      }
    },
    connect,
    disconnect
  };
};
var createAnalyzer = (context, options) => {
  const analyser = createAnalyserNode(context, options);
  const getByteFrequencyData = analyser.getByteFrequencyData.bind(analyser);
  const getByteTimeDomainData = analyser.getByteTimeDomainData.bind(analyser);
  const getFloatFrequencyData = analyser.getFloatFrequencyData.bind(analyser);
  const connect = analyser.connect.bind(analyser);
  const disconnect = analyser.disconnect.bind(analyser);
  let byteBuffer;
  const getFloatTimeDomainData = (buffer) => {
    if ("getFloatTimeDomainData" in AnalyserNode.prototype) {
      return analyser.getFloatTimeDomainData(buffer);
    }
    if (!byteBuffer) {
      byteBuffer = new Uint8Array(buffer.length);
    }
    analyser.getByteTimeDomainData(byteBuffer);
    return copyByteBufferToFloatBuffer(byteBuffer, buffer);
  };
  const getAverageVolume = (buffer) => {
    getFloatTimeDomainData(buffer);
    return processAverageVolume(Array.from(buffer));
  };
  return {
    get node() {
      return analyser;
    },
    get frequencyBinCount() {
      return analyser.frequencyBinCount;
    },
    get fftSize() {
      return analyser.fftSize;
    },
    set fftSize(size) {
      analyser.fftSize = size;
    },
    get minDecibels() {
      return analyser.minDecibels;
    },
    set minDecibels(decibels) {
      analyser.minDecibels = decibels;
    },
    get maxDecibels() {
      return analyser.maxDecibels;
    },
    set maxDecibels(decibels) {
      analyser.maxDecibels = decibels;
    },
    get smoothingTimeConstant() {
      return analyser.smoothingTimeConstant;
    },
    set smoothingTimeConstant(constant) {
      analyser.smoothingTimeConstant = constant;
    },
    getByteTimeDomainData,
    getByteFrequencyData,
    getFloatFrequencyData,
    getFloatTimeDomainData,
    getAverageVolume,
    connect,
    disconnect
  };
};
var subscribeWorkletNode = (workletNode, { messageHandler, errorHandler } = {}) => {
  const subscribeMessage = (event) => {
    messageHandler?.(event.data);
  };
  const subscribeToPortError = (event) => {
    if (errorHandler) {
      errorHandler(event);
    }
  };
  const subscribeToProcessorError = (event) => {
    if (errorHandler) {
      errorHandler(event);
    }
  };
  workletNode.port.addEventListener("message", subscribeMessage);
  workletNode.port.start();
  workletNode.addEventListener("processorerror", subscribeToProcessorError);
  workletNode.port.addEventListener("messageerror", subscribeToPortError);
  return () => {
    workletNode.port.postMessage({ type: "release" });
    workletNode.port.removeEventListener("message", subscribeMessage);
    workletNode.removeEventListener(
      "processorerror",
      subscribeToProcessorError
    );
    workletNode.port.removeEventListener(
      "messageerror",
      subscribeToPortError
    );
  };
};
var TIMEOUT_DELAY_ADJUSTMENT = 0.5;
var subscribeTimeoutAnalyzerNode = (analyzer, { messageHandler, updateFrequency = 2 }) => {
  const timeoutMs = updateFrequency * 1e3 * TIMEOUT_DELAY_ADJUSTMENT;
  let timeoutId = 0;
  let stopTimeout = false;
  const clearTimeout = () => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
      timeoutId = 0;
    }
  };
  const process = () => {
    messageHandler(analyzer);
    clearTimeout();
    if (!stopTimeout) {
      timeoutId = window.setTimeout(process, timeoutMs);
    }
  };
  process();
  return () => {
    stopTimeout = true;
    clearTimeout();
  };
};
function createBaseAudioNode(name, create, release) {
  const props = {
    name,
    node: void 0,
    audioNode: void 0,
    outputs: /* @__PURE__ */ new WeakSet()
  };
  const createNode = (context, prevNode) => {
    const [audioNode, node] = create(context, prevNode);
    props.audioNode = audioNode;
    props.node = node;
    return [audioNode, node];
  };
  const releaseNode = () => {
    release?.();
    props.outputs = /* @__PURE__ */ new WeakSet();
    props.audioNode?.disconnect();
    props.audioNode = void 0;
  };
  const connectNode = (param) => {
    if (!param) {
      return;
    }
    if (!props.audioNode) {
      throw new Error("Source AudioNode is not initialized");
    }
    const [destination, output, input] = Array.isArray(param) ? param : [param];
    if (isAudioParam(destination)) {
      props.outputs.add(destination);
      return props.audioNode.connect(destination, output);
    }
    if (isAudioNodeInit(destination)) {
      if (!destination.audioNode) {
        throw new Error("Destination AudioNode is not initialized");
      }
      props.outputs.add(destination);
      return props.audioNode.connect(
        destination.audioNode,
        output,
        input
      );
    }
  };
  const disconnectNode = (destInit) => {
    if (!destInit) {
      return;
    }
    if (!props.audioNode) {
      throw new Error("AudioNode is not initialized");
    }
    const [destination, output, input] = Array.isArray(destInit) ? destInit : [destInit];
    if (destination && !props.outputs.has(destination)) {
      return;
    }
    if (isAudioParam(destination)) {
      props.outputs.delete(destination);
      if (output !== void 0) {
        return props.audioNode.disconnect(destination, output);
      }
      return props.audioNode.disconnect(destination);
    }
    if (isAudioNodeInit(destination)) {
      if (!destination.audioNode) {
        throw new Error("Destination AudioNode is not initialized");
      }
      props.outputs.delete(destination);
      if (output !== void 0) {
        if (input !== void 0) {
          return props.audioNode.disconnect(
            destination.audioNode,
            output,
            input
          );
        }
        return props.audioNode.disconnect(
          destination.audioNode,
          output
        );
      }
      return props.audioNode.disconnect(destination.audioNode);
    }
    props.outputs = /* @__PURE__ */ new WeakSet();
    props.audioNode.disconnect();
  };
  const hasConnectedTo = (init) => {
    return props.outputs.has(init);
  };
  return Object.assign(props, {
    create: createNode,
    connect: connectNode,
    disconnect: disconnectNode,
    release: releaseNode,
    hasConnectedTo,
    toJSON: () => ({
      name: props.name,
      node: props.node,
      audioNode: props.audioNode
    })
  });
}
var createStreamSourceGraphNode = (mediaStream, shouldResetEnabled = true) => {
  let stream = void 0;
  return createBaseAudioNode(
    "source",
    (context) => {
      stream = createMediaStreamAudioClone(mediaStream);
      if (shouldResetEnabled) {
        stream.getAudioTracks().forEach((track) => track.enabled = true);
      }
      const node = createMediaStreamAudioSourceNode(context, {
        mediaStream: stream
      });
      return [node, node];
    },
    () => {
      stopStreamTracks(stream);
      stream = void 0;
    }
  );
};
var createMediaElementSourceGraphNode = (mediaElement) => {
  return createBaseAudioNode("source", (context) => {
    const node = createMediaElementSourceNode(context, {
      mediaElement
    });
    return [node, node];
  });
};
var createAnalyzerSubscribableGraphNode = ({
  messageHandler,
  updateFrequency = 2,
  ...analyserOptions
}) => {
  let unsubscribe;
  return createBaseAudioNode(
    "analyzer",
    (context) => {
      const node = createAnalyzer(context, analyserOptions);
      unsubscribe = subscribeTimeoutAnalyzerNode(node, {
        messageHandler,
        updateFrequency
      });
      return [node.node, node];
    },
    () => {
      unsubscribe?.();
      unsubscribe = void 0;
    }
  );
};
var createDenoiseWorkletGraphNode = (data, messageHandler) => {
  let unsubscribe;
  return createBaseAudioNode(
    "denoise",
    (context, prevNode) => {
      const sampleRate = context.sampleRate;
      const channelCount = prevNode?.channelCount ?? 1;
      const node = createDenoiseWorkletNode(context, {
        outputChannelCount: [channelCount],
        processorOptions: {
          data,
          sampleRate,
          shouldSendVAD: !!messageHandler
        }
      });
      unsubscribe = subscribeWorkletNode(node, { messageHandler });
      return [node, node];
    },
    () => {
      unsubscribe?.();
      unsubscribe = void 0;
    }
  );
};
var createGainGraphNode = (mute) => {
  return createBaseAudioNode("gain", (context) => {
    const node = createGainWithMute(context, mute);
    return [node.node, node];
  });
};
var createAnalyzerGraphNode = (options) => {
  return createBaseAudioNode("analyzer", (context) => {
    const node = createAnalyzer(context, options);
    return [node.node, node];
  });
};
var createStreamDestinationGraphNode = (options) => {
  let node = void 0;
  return createBaseAudioNode(
    "destination",
    (context) => {
      node = createMediaStreamAudioDestinationNode(context, options);
      return [node, node];
    },
    () => {
      stopStreamTracks(node?.stream);
      node = void 0;
    }
  );
};
var createAudioDestinationGraphNode = () => {
  return createBaseAudioNode("destination", (context) => {
    const node = context.destination;
    return [node, node];
  });
};
var createDelayGraphNode = (options) => {
  return createBaseAudioNode("delay", (context) => {
    const node = createDelayNode(context, options);
    return [node, node];
  });
};
var createChannelSplitterGraphNode = (options) => {
  return createBaseAudioNode("splitter", (context) => {
    const node = createChannelSplitterNode(context, options);
    return [node, node];
  });
};
var createChannelMergerGraphNode = (options) => {
  return createBaseAudioNode("merger", (context) => {
    const node = createChannelMergerNode(context, options);
    return [node, node];
  });
};
var createNodeInitDisconnector = () => (srcInit, destInit) => {
  if (!srcInit) {
    return;
  }
  const [source, outputIdx, inputIdx] = Array.isArray(srcInit) ? srcInit : [srcInit];
  if (!source) {
    return;
  }
  if (isAudioParam(source)) {
    throw new Error(
      "AudioParam cannot be used as the source of disconnect"
    );
  }
  const [destination] = Array.isArray(destInit) ? destInit : [destInit];
  return source.disconnect([destination, outputIdx, inputIdx]);
};
var createNodeInitConnector = (context) => (srcInit, destInit) => {
  if (!srcInit || !destInit) {
    return;
  }
  const [source, outputIdx, inputIdx] = Array.isArray(srcInit) ? srcInit : [srcInit];
  const [destination] = Array.isArray(destInit) ? destInit : [destInit];
  if (!source || !destination) {
    return;
  }
  if (isAudioParam(source)) {
    throw new Error(
      "AudioParam cannot be used as the source of connect"
    );
  }
  const [sourceNode] = source.audioNode ? [source.audioNode] : source.create(context);
  if (isAudioNodeInit(destination) && !destination.audioNode) {
    destination.create(context, sourceNode);
  }
  return source.connect([destination, outputIdx, inputIdx]);
};
var createAudioGraph = (initialConnections, options = {}) => {
  const props = {
    closing: false,
    inits: /* @__PURE__ */ new Set()
  };
  const audioContext = options.context ?? createAudioContext(options.contextOptions);
  resumeAudioOnInterruption(audioContext);
  const connectInit = createNodeInitConnector(audioContext);
  const disconnectInit = createNodeInitDisconnector();
  const connect = (sequence) => {
    sequence.forEach((initParam, idx, initParams) => {
      const prevParam = initParams[idx - 1];
      const [currentInit] = Array.isArray(initParam) ? initParam : [initParam];
      connectInit(prevParam, currentInit);
      if (isAudioNodeInit(currentInit)) {
        props.inits.add(currentInit);
      }
    });
  };
  const disconnect = (sequence) => {
    sequence.forEach((initParam, idx, initParams) => {
      const prevParam = initParams[idx - 1];
      const [currentInit] = Array.isArray(initParam) ? initParam : [initParam];
      disconnectInit(prevParam, currentInit);
    });
  };
  const addWorklet = async (moduleURL, options2) => {
    if (!props.workletModule) {
      await audioContext.audioWorklet.addModule(moduleURL, options2);
      props.workletModule = { moduleURL, options: options2 };
    }
  };
  initialConnections.forEach(connect);
  const releaseInit = (init) => {
    if (props.inits.has(init)) {
      init.release();
      props.inits.delete(init);
    }
  };
  return {
    get inits() {
      return Array.from(props.inits);
    },
    get context() {
      return audioContext;
    },
    get state() {
      return props.closing ? "closing" : audioContext.state;
    },
    connect,
    disconnect,
    addWorklet,
    releaseInit,
    release: () => {
      return new Promise((resolve) => {
        if (!props.closing && audioContext.state !== "closed") {
          props.closing = true;
          props.workletModule = void 0;
          void audioContext.close().then(() => {
            props.closing = false;
            props.inits.forEach(releaseInit);
            resolve();
          });
        } else {
          resolve();
        }
      });
    }
  };
};
var createAudioGraphProxy = (audioGraph, handlers) => new Proxy(audioGraph, {
  get: (target, p) => {
    switch (p) {
      case "connect": {
        const r = target[p];
        return (...args) => {
          handlers.connect?.(target, args);
          return r.apply(target, args);
        };
      }
      case "disconnect": {
        const r = target[p];
        return (...args) => {
          handlers.disconnect?.(target, args);
          return r.apply(target, args);
        };
      }
      default: {
        return target[p];
      }
    }
  },
  set: () => false
});

// src/video/load.ts
var policy = {
  createScriptURL: (url) => {
    if (new URL(url, document.baseURI).origin !== window.location.origin) {
      throw new Error(
        `Trying to create script url not on same origin (${url} not on ${window.location.origin})`
      );
    }
    return url;
  }
};
var sameOriginPolicy = typeof window.trustedTypes !== "undefined" && window.trustedTypes.createPolicy ? window.trustedTypes.createPolicy("same-origin", policy) : policy;
var loadScript = (path, id) => new Promise((resolve, reject) => {
  const scriptExist = document.getElementById(id);
  if (scriptExist) {
    return;
  }
  const removeScript = () => {
    document.head.removeChild(script);
  };
  const script = document.createElement("script");
  script.src = sameOriginPolicy.createScriptURL(path);
  script.id = id;
  script.onload = () => {
    removeScript();
    resolve();
  };
  script.onerror = (ev) => {
    removeScript();
    reject(ev);
  };
  document.head.appendChild(script);
});
var loadWasms = async (paths) => {
  await Promise.all(paths.map(([path]) => loadScript(path, path)));
};
var loadTfjsCore = async (prodMode) => {
  const tfjs = await import("@tensorflow/tfjs-core");
  if (prodMode) {
    tfjs.enableProdMode();
  }
  return tfjs;
};
var loadTfjsBackendWebGl = () => import("@tensorflow/tfjs-backend-webgl");

// src/video/constants.ts
var PROCESSING_WIDTH = 768;
var PROCESSING_HEIGHT = 432;
var FOREGROUND_THRESHOLD = 0.5;
var BACKGROUND_BLUR_AMOUNT = 3;
var EDGE_BLUR_AMOUNT = 3;
var FLIP_HORIZONTAL = false;
var FRAME_RATE = 20;
var AbortReason = /* @__PURE__ */ ((AbortReason2) => {
  AbortReason2["Close"] = "close";
  return AbortReason2;
})(AbortReason || {});

// src/video/video.ts
var createVideoProcessor = (transformers, processTrack) => {
  const props = {
    processing: false
  };
  const open = async () => {
    await Promise.all(transformers.map((transformer) => transformer.init()));
  };
  const process = async (source) => {
    const [track] = source.getVideoTracks();
    if (!track) {
      return source;
    }
    if (props.processing) {
      throw new Error("Cannot process when it is already processing");
    }
    props.abortController = new AbortController();
    const trackGenerated = await processTrack(track, transformers, {
      signal: props.abortController.signal
    });
    props.outputStream = new MediaStream([
      trackGenerated,
      ...source.getAudioTracks().map((track2) => track2.clone())
    ]);
    props.processing = true;
    return props.outputStream;
  };
  const close = () => {
    if (!props.processing) {
      return;
    }
    props.abortController?.abort("close" /* Close */);
    stopStreamTracks(props.outputStream);
    props.outputStream = void 0;
    props.processing = false;
  };
  const destroy = async () => {
    close();
    await Promise.all(
      transformers.map((transformer) => transformer.destroy())
    );
  };
  return {
    open,
    process,
    close,
    destroy
  };
};

// src/video/types.ts
var RENDER_EFFECTS = [
  "none",
  "blur",
  "overlay"
];
var SEG_MODELS = [
  "mediapipeSelfie"
];

// src/video/utils.ts
import { Tensor, browser } from "@tensorflow/tfjs-core/dist/base";
var createCanvas = (width, height) => {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  return canvas;
};
var createOffscreenCanvas = (width, height) => {
  try {
    const offscreen = new OffscreenCanvas(width, height);
    return offscreen;
  } catch {
    return createCanvas(width, height);
  }
};
var createVideoElement = (width, height) => {
  const video = document.createElement("video");
  video.width = width;
  video.height = height;
  video.muted = true;
  return video;
};
var setVideoElementSrc = (video, src) => {
  let url = "";
  const revokeObjectURL = () => {
    if (url) {
      URL.revokeObjectURL(url);
    }
  };
  if (src instanceof MediaStream) {
    src.getVideoTracks().forEach((track) => {
      track.addEventListener("ended", revokeObjectURL);
    });
  }
  if ("MediaSource" in window && src instanceof MediaSource) {
    src.addEventListener("sourceended", revokeObjectURL);
  }
  try {
    video.srcObject = src;
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === "TypeError") {
        throw error;
      }
      if ("MediaSource" in window && src instanceof MediaSource || src instanceof Blob) {
        url = URL.createObjectURL(src);
      }
      video.src = url;
    }
  }
};
var toVideoElement = (input, width, height) => {
  const [settings = {}] = input.getVideoTracks().map((track) => track.getSettings());
  const video = createVideoElement(
    settings.width ?? width,
    settings.height ?? height
  );
  video.playsInline = true;
  setVideoElementSrc(video, input);
  return video;
};
var playVideo = async (video) => {
  if (video.autoplay) {
    return;
  }
  if (video.readyState < 2 /* HaveCurrentData */) {
    await Promise.race([
      new Promise((resolve) => {
        const waitForEvent = () => {
          video.removeEventListener("loadeddata", waitForEvent);
          resolve();
        };
        video.addEventListener("loadeddata", waitForEvent);
      }),
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 3e3);
      })
    ]);
  }
  return await video.play();
};
var loadImage = async (image, src) => {
  image.src = src;
  return new Promise((resolve, reject) => {
    image.onload = () => resolve();
    image.onerror = reject;
  });
};
var toNumber = (value) => value instanceof SVGAnimatedLength ? value.baseVal.value : value;
var getCanvasRenderingContext2D = (canvas, options) => {
  const context = canvas.getContext("2d", options);
  if (!context) {
    throw new Error("Cannot get CanvasRenderingContext2D");
  }
  return context;
};
var getImageSize = (image) => {
  if (image instanceof Tensor) {
    const [height = 0, width = 0] = image.shape.slice(0, 2);
    return { height, width };
  }
  if ("VideoFrame" in window && image instanceof VideoFrame) {
    return { height: image.displayHeight, width: image.displayWidth };
  }
  if ("offsetHeight" in image && image.offsetHeight !== 0 && "offsetWidth" in image && image.offsetWidth !== 0) {
    return { height: image.offsetHeight, width: image.offsetWidth };
  }
  if ("height" in image && image.height !== 0 && "width" in image && image.width !== 0) {
    return { height: toNumber(image.height), width: toNumber(image.width) };
  }
  throw new Error("Unknown input image");
};
var toHTMLCanvasElementLossy = async (image) => {
  const { width, height } = getImageSize(image);
  const canvas = createCanvas(width, height);
  if (image instanceof Tensor) {
    await browser.toPixels(image, canvas);
    return canvas;
  }
  const context = getCanvasRenderingContext2D(canvas);
  if (image instanceof ImageData) {
    context.putImageData(image, 0, 0);
  } else {
    context.drawImage(image, 0, 0);
  }
  return canvas;
};
var toImageDataLossy = async (image) => {
  const { width, height } = getImageSize(image);
  if (image instanceof Tensor) {
    return new ImageData(await browser.toPixels(image), width, height);
  }
  const canvas = createOffscreenCanvas(width, height);
  const context = getCanvasRenderingContext2D(canvas);
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, canvas.width, canvas.height);
};
var toTensorLossy = async (image) => {
  const pixelsInput = image instanceof SVGImageElement || image instanceof OffscreenCanvas ? await toHTMLCanvasElementLossy(image) : image;
  return browser.fromPixels(pixelsInput, 4);
};
var toSegmentation = (data, type, maskValueToLabel) => {
  const mask = {
    toCanvasImageSource: async () => {
      if (data instanceof HTMLCanvasElement) {
        return data;
      }
      if (data instanceof HTMLVideoElement || data instanceof ImageBitmap || data instanceof HTMLImageElement) {
        const canvas = createCanvas(data.width, data.height);
        const context = canvas.getContext("2d");
        context?.drawImage(data, 0, 0, data.width, data.height);
        return canvas;
      }
      return await toHTMLCanvasElementLossy(data);
    },
    toImageData: async () => {
      if (data instanceof ImageData) {
        return data;
      }
      return await toImageDataLossy(data);
    },
    toTensor: async () => {
      if (data instanceof Tensor) {
        return data;
      }
      return await toTensorLossy(data);
    },
    getUnderlyingType: () => type
  };
  return {
    maskValueToLabel,
    mask
  };
};
var flipCanvasHorizontal = (canvas) => {
  const ctx = getCanvasRenderingContext2D(canvas);
  ctx.scale(-1, 1);
  ctx.translate(-canvas.width, 0);
};
var drawStroke = (bytes, row, column, width, radius, color = {
  r: 0,
  g: 255,
  b: 255,
  a: 255
}) => {
  for (let i = -radius; i <= radius; i++) {
    for (let j = -radius; j <= radius; j++) {
      if (i !== 0 && j !== 0) {
        const n = (row + i) * width + (column + j);
        bytes[4 * n + 0] = color.r;
        bytes[4 * n + 1] = color.g;
        bytes[4 * n + 2] = color.b;
        bytes[4 * n + 3] = color.a;
      }
    }
  }
};
var isSegmentationBoundary = (data, row, column, width, isForegroundId, alphaCutoff, radius = 1) => {
  let numberBackgroundPixels = 0;
  for (let i = -radius; i <= radius; i++) {
    for (let j = -radius; j <= radius; j++) {
      if (i !== 0 && j !== 0) {
        const n = (row + i) * width + (column + j);
        const foregroundColor = data[4 * n];
        const alphaColor = data[4 * n + 3];
        if (foregroundColor !== void 0 && !isForegroundId[foregroundColor] || alphaColor !== void 0 && alphaColor < alphaCutoff) {
          numberBackgroundPixels += 1;
        }
      }
    }
  }
  return numberBackgroundPixels > 0;
};
var toBinaryMask = async (segmentation, foreground = {
  r: 0,
  g: 0,
  b: 0,
  a: 0
}, background = {
  r: 0,
  g: 0,
  b: 0,
  a: 255
}, drawContour = false, foregroundThreshold = 0.5, foregroundMaskValues = Array.from(Array(256).keys())) => {
  const segmentations = !Array.isArray(segmentation) ? [segmentation] : segmentation;
  if (segmentations.length === 0) {
    return null;
  }
  const masks = await Promise.all(
    segmentations.map((segmentation2) => segmentation2.mask.toImageData())
  );
  const [imageData] = masks;
  if (!imageData) {
    return null;
  }
  const { width, height } = imageData;
  const bytes = new Uint8ClampedArray(width * height * 4);
  const alphaCutoff = Math.round(255 * foregroundThreshold);
  const isForegroundId = new Array(256).fill(false);
  foregroundMaskValues.forEach((id) => isForegroundId[id] = true);
  for (let i = 0; i < height; i++) {
    for (let j = 0; j < width; j++) {
      const n = i * width + j;
      bytes[4 * n + 0] = background.r;
      bytes[4 * n + 1] = background.g;
      bytes[4 * n + 2] = background.b;
      bytes[4 * n + 3] = background.a;
      for (const mask of masks) {
        const maskForegroundColor = mask.data[4 * n];
        const maskAlphaColor = mask.data[4 * n + 3];
        if (maskForegroundColor !== void 0 && isForegroundId[maskForegroundColor] && maskAlphaColor !== void 0 && maskAlphaColor >= alphaCutoff) {
          bytes[4 * n] = foreground.r;
          bytes[4 * n + 1] = foreground.g;
          bytes[4 * n + 2] = foreground.b;
          bytes[4 * n + 3] = foreground.a;
          if (drawContour && i - 1 >= 0 && i + 1 < height && j - 1 >= 0 && j + 1 < width && isSegmentationBoundary(
            mask.data,
            i,
            j,
            width,
            isForegroundId,
            alphaCutoff
          )) {
            drawStroke(bytes, i, j, width, 1);
          }
        }
      }
    }
  }
  return new ImageData(bytes, width, height);
};
var createInputImageConvertor = (draw) => async (input) => {
  return input instanceof ImageData || input instanceof ImageBitmap ? await draw(input) : input;
};
var ensure = (prop, message = "Processor is not opened, please call open() method first") => {
  if (!prop) {
    throw new Error(message);
  }
  return prop;
};
var hasRequestVideoFrameCallback = (input) => typeof input === "object" && input instanceof HTMLVideoElement && "requestVideoFrameCallback" in HTMLVideoElement.prototype;
var getFrameRate = (input, frameRate) => {
  if (input instanceof HTMLVideoElement) {
    const quality = input.getVideoPlaybackQuality();
    return input.mozPresentedFrames || quality.totalVideoFrames - quality.droppedVideoFrames;
  }
  return frameRate;
};
var createFrameCallbackRequest = (callback, frameRate, {
  subscribeVisibilityChange = subscribeVisibilityChangeEvent
} = {}) => {
  const props = { callbackId: 0, frameRate, started: false };
  const getCallbackLoopRunner = (input) => {
    if (!props.runner) {
      const fallbackRequestCallback = async (input2) => {
        if (props.runner) {
          props.runner.frameRate = getFrameRate(
            input2,
            props.frameRate
          );
        }
        await callback(input2);
      };
      props.runner = createAsyncCallbackLoop(
        fallbackRequestCallback,
        getFrameRate(input, props.frameRate)
      );
    }
    return props.runner;
  };
  const cancelFrameCallback = () => {
    if (hasRequestVideoFrameCallback(props.input) && props.callbackId) {
      props.input.cancelVideoFrameCallback(props.callbackId);
      props.callbackId = 0;
    }
  };
  return {
    start: async (input) => {
      props.input = input;
      if (hasRequestVideoFrameCallback(input)) {
        props.unsubscribe = subscribeVisibilityChange(async (hidden) => {
          if (props.started && props.input) {
            if (hidden && props.callbackId) {
              await getCallbackLoopRunner(props.input).start(
                props.input
              );
            } else {
              getCallbackLoopRunner(props.input).stop();
            }
          }
        });
        await new Promise((resolve) => {
          const wrap = async () => {
            await callback(input);
            if (!props.started) {
              props.started = true;
            }
            resolve();
            props.callbackId = input.requestVideoFrameCallback(wrap);
          };
          props.callbackId = input.requestVideoFrameCallback(wrap);
        });
      } else {
        await getCallbackLoopRunner(input).start(input);
        props.started = true;
      }
    },
    stop: () => {
      cancelFrameCallback();
      props.runner?.stop();
      props.unsubscribe?.();
      props.started = false;
    },
    get frameRate() {
      return getFrameRate(props.input, frameRate);
    },
    set frameRate(value) {
      props.frameRate = value;
      if (props.runner) {
        props.runner.frameRate = frameRate;
      }
    }
  };
};

// src/video/canvasRenderUtils.ts
import { Tensor as Tensor2, browser as browser2 } from "@tensorflow/tfjs-core/dist/base";
var createCanvasRenderUtils = (processingWidth, processingHeight) => {
  const props = {};
  const getImage = (imageName) => {
    const image = props[imageName];
    if (!image) {
      const img = new Image();
      props[imageName] = img;
      return img;
    }
    return image;
  };
  const getCanvas = (canvasName) => {
    const canvas = props[canvasName];
    if (!canvas) {
      const canvas2 = createOffscreenCanvas(
        processingWidth,
        processingHeight
      );
      props[canvasName] = canvas2;
      return canvas2;
    }
    return canvas;
  };
  const renderImageDataToOffScreenCanvas = (image, canvasName) => {
    const canvas = getCanvas(canvasName);
    const context = getCanvasRenderingContext2D(canvas, {
      desynchronized: true
    });
    context.putImageData(image, 0, 0);
    return canvas;
  };
  const drawImage = async (ctx, image, sx, sy, sw, sh, dx, dy, dw, dh) => {
    if (image instanceof Tensor2) {
      const pixels = await browser2.toPixels(image);
      const { height, width } = getImageSize(image);
      image = new ImageData(pixels, width, height);
    }
    const source = image instanceof ImageData ? renderImageDataToOffScreenCanvas(image, "drawImageDataCanvas") : image;
    if (sw === void 0 || sh === void 0) {
      ctx.drawImage(source, sx, sy);
    } else if (dx === void 0 || dy === void 0 || dw === void 0 || dh === void 0) {
      ctx.drawImage(source, sx, sy, sw, sh);
    } else {
      ctx.drawImage(source, sx, sy, sw, sh, dx, dy, dw, dh);
    }
  };
  const renderImageToCanvas = async (image, canvas, dw = processingWidth, dh = processingHeight, options = {}) => {
    const { height, width } = getImageSize(image);
    const rect = fitDestinationSize(width, height, dw, dh);
    const ctx = getCanvasRenderingContext2D(canvas, {
      desynchronized: true,
      ...options
    });
    await drawImage(ctx, image, rect.x, rect.y, rect.width, rect.height);
  };
  const renderImageToOffScreenCanvas = async (image, canvasName) => {
    const canvas = getCanvas(canvasName);
    await renderImageToCanvas(image, canvas);
    return canvas;
  };
  const drawWithCompositing = async (ctx, image, compositeOperation) => {
    ctx.globalCompositeOperation = compositeOperation;
    await drawImage(ctx, image, 0, 0);
  };
  const cpuBlur = async (canvas, image, blur) => {
    const ctx = getCanvasRenderingContext2D(canvas, { desynchronized: true });
    let sum2 = 0;
    const delta = 5;
    const alphaLeft = 1 / (2 * Math.PI * delta * delta);
    const step = blur < 3 ? 1 : 2;
    for (let y = -blur; y <= blur; y += step) {
      for (let x = -blur; x <= blur; x += step) {
        const weight = alphaLeft * Math.exp(-(x * x + y * y) / (2 * delta * delta));
        sum2 += weight;
      }
    }
    for (let y = -blur; y <= blur; y += step) {
      for (let x = -blur; x <= blur; x += step) {
        ctx.globalAlpha = alphaLeft * Math.exp(-(x * x + y * y) / (2 * delta * delta)) / sum2 * blur;
        await drawImage(ctx, image, x, y);
      }
    }
    ctx.globalAlpha = 1;
  };
  const drawAndBlurImageOnCanvas = async (image, blurAmount, canvas) => {
    const { height, width } = getImageSize(image);
    const ctx = getCanvasRenderingContext2D(canvas, { desynchronized: true });
    ctx.clearRect(0, 0, width, height);
    if (blurAmount <= 0) {
      return drawImage(ctx, image, 0, 0, width, height);
    }
    ctx.save();
    if ("filter" in ctx) {
      await drawImage(ctx, image, 0, 0, width, height);
      ctx.filter = `blur(${blurAmount}px)`;
      await drawImage(ctx, image, 0, 0, width, height);
    } else {
      await cpuBlur(canvas, image, blurAmount);
    }
    ctx.restore();
  };
  const drawAndBlurImageOnOffScreenCanvas = async (image, blurAmount, offscreenCanvasName) => {
    const canvas = getCanvas(offscreenCanvasName);
    if (blurAmount === 0) {
      await renderImageToCanvas(image, canvas);
    } else {
      await drawAndBlurImageOnCanvas(image, blurAmount, canvas);
    }
    return canvas;
  };
  const createPersonMask = async (segmentation, foregroundThreshold, edgeBlurAmount) => {
    const backgroundMaskImage = await toBinaryMask(
      segmentation,
      { r: 0, g: 0, b: 0, a: 255 },
      { r: 0, g: 0, b: 0, a: 0 },
      false,
      foregroundThreshold
    );
    if (!backgroundMaskImage) {
      return getCanvas("maskCanvas");
    }
    const backgroundMask = renderImageDataToOffScreenCanvas(
      backgroundMaskImage,
      "maskCanvas"
    );
    if (edgeBlurAmount === 0) {
      return backgroundMask;
    } else {
      return drawAndBlurImageOnOffScreenCanvas(
        backgroundMask,
        edgeBlurAmount,
        "blurredMaskCanvas"
      );
    }
  };
  const loadImageElement = async (url, imageName) => {
    const image = getImage(imageName);
    await loadImage(image, url);
    return image;
  };
  const loadAndDrawImageOnOffscreenCanvas = async (url, canvasName, imageName) => {
    const image = await loadImageElement(url, imageName);
    const canvas = getCanvas(canvasName);
    const context = getCanvasRenderingContext2D(canvas, {
      desynchronized: true
    });
    const imageSize = getImageSize(image);
    const rect = fitDestinationSize(
      imageSize.width,
      imageSize.height,
      processingWidth,
      processingHeight
    );
    await drawImage(
      context,
      image,
      rect.x,
      rect.y,
      rect.width,
      rect.height
    );
    return canvas;
  };
  const loadBackgroundImage = (url) => loadAndDrawImageOnOffscreenCanvas(
    url,
    "backgroundImageCanvas",
    "backgroundImage"
  );
  const drawBokehEffect = async (canvas, inputImage, backgroundImage, segmentations, foregroundThreshold = 0.5, backgroundBlurAmount = 3, edgeBlurAmount = 3, flipHorizontal = false) => {
    const blurredImage = await drawAndBlurImageOnOffScreenCanvas(
      backgroundImage,
      backgroundBlurAmount,
      "blurredCanvas"
    );
    const ctx = getCanvasRenderingContext2D(canvas, { desynchronized: true });
    if (Array.isArray(segmentations) && segmentations.length === 0) {
      return drawImage(ctx, blurredImage, 0, 0);
    }
    const personMask = await createPersonMask(
      segmentations,
      foregroundThreshold,
      edgeBlurAmount
    );
    ctx.save();
    if (flipHorizontal) {
      flipCanvasHorizontal(canvas);
    }
    const { height, width } = getImageSize(inputImage);
    await drawImage(ctx, inputImage, 0, 0, width, height);
    await drawWithCompositing(ctx, personMask, "destination-in");
    await drawWithCompositing(ctx, blurredImage, "destination-over");
    ctx.restore();
  };
  const drawBlurEffect = (canvas, inputImage, segmentations, foregroundThreshold = 0.5, backgroundBlurAmount = 3, edgeBlurAmount = 3, flipHorizontal = false) => drawBokehEffect(
    canvas,
    inputImage,
    inputImage,
    segmentations,
    foregroundThreshold,
    backgroundBlurAmount,
    edgeBlurAmount,
    flipHorizontal
  );
  const drawOverlayEffect = (canvas, inputImage, backgroundImage, segmentations, foregroundThreshold = 0.5, backgroundBlurAmount = 0, edgeBlurAmount = 3, flipHorizontal = false) => drawBokehEffect(
    canvas,
    inputImage,
    backgroundImage,
    segmentations,
    foregroundThreshold,
    backgroundBlurAmount,
    edgeBlurAmount,
    flipHorizontal
  );
  const evaluateInput = async (inputImage) => {
    const image = await renderImageToOffScreenCanvas(
      inputImage,
      "inputCanvas"
    );
    return image;
  };
  return {
    evaluateInput,
    renderImageToCanvas,
    drawBlurEffect,
    drawOverlayEffect,
    loadBackgroundImage,
    renderImageToOffScreenCanvas,
    renderImageDataToOffScreenCanvas,
    drawAndBlurImageOnOffScreenCanvas
  };
};

// src/video/canvasTransform.ts
var createAssertInRange = (from, to) => (value) => {
  if (value < from || value > to) {
    throw new Error(`Invalid value (${value}) to range [${from}, ${to}]`);
  }
};
var assertInRangeFrom0To1 = createAssertInRange(0, 1);
var assertInRangeFrom0To20 = createAssertInRange(0, 20);
var NOT_INITED_ERROR_MSG = "Please call init() method first!";
var createTransform = (segmenter, {
  width = PROCESSING_WIDTH,
  height = PROCESSING_HEIGHT,
  foregroundThreshold = FOREGROUND_THRESHOLD,
  backgroundBlurAmount = BACKGROUND_BLUR_AMOUNT,
  edgeBlurAmount = EDGE_BLUR_AMOUNT,
  flipHorizontal = FLIP_HORIZONTAL,
  effects = "none",
  selfManageSegmenter,
  bgImageUrl
} = {}) => {
  const props = {
    segmenter,
    width,
    height,
    foregroundThreshold,
    backgroundBlurAmount,
    edgeBlurAmount,
    flipHorizontal,
    utils: createCanvasRenderUtils(width, height),
    effects,
    backgroundImage: void 0,
    status: "created",
    backgroundImageUrl: bgImageUrl
  };
  const processInput = async (input) => {
    if (props.segmenter.status === "created" || props.segmenter.status === "closed") {
      await props.segmenter.open();
    }
    if (props.segmenter.status === "opening") {
      return [];
    }
    return await props.segmenter.process(input);
  };
  const loadBackgroundImage = async (url) => {
    if (props.backgroundImageUrl === url && props.backgroundImage) {
      return;
    }
    props.backgroundImageUrl = url;
    props.backgroundImage = await props.utils.loadBackgroundImage(url);
  };
  return {
    get status() {
      return props.status;
    },
    get width() {
      return props.width;
    },
    get height() {
      return props.height;
    },
    get foregroundThreshold() {
      return props.foregroundThreshold;
    },
    get backgroundBlurAmount() {
      return props.backgroundBlurAmount;
    },
    get edgeBlurAmount() {
      return props.edgeBlurAmount;
    },
    get flipHorizontal() {
      return props.flipHorizontal;
    },
    get effects() {
      return props.effects;
    },
    get backgroundImage() {
      return props.backgroundImage;
    },
    set foregroundThreshold(value) {
      assertInRangeFrom0To1(value);
      props.foregroundThreshold = value;
    },
    set backgroundBlurAmount(value) {
      assertInRangeFrom0To20(value);
      props.backgroundBlurAmount = value;
    },
    set edgeBlurAmount(value) {
      assertInRangeFrom0To20(value);
      props.edgeBlurAmount = value;
    },
    set flipHorizontal(value) {
      props.flipHorizontal = value;
    },
    set effects(value) {
      props.effects = value;
    },
    get backgroundImageUrl() {
      return props.backgroundImageUrl;
    },
    set backgroundImage(canvas) {
      props.backgroundImage = canvas;
    },
    loadBackgroundImage,
    get segmenter() {
      return props.segmenter;
    },
    set segmenter(value) {
      if (value !== props.segmenter) {
        props.segmenter = value;
      }
    },
    init: async () => {
      props.outputCanvas = createCanvas(width, height);
      if (props.backgroundImageUrl) {
        await loadBackgroundImage(props.backgroundImageUrl);
      }
      props.status = "opened";
    },
    transform: async (videoFrame, controller) => {
      if (!props.outputCanvas) {
        throw new Error(NOT_INITED_ERROR_MSG);
      }
      switch (props.effects) {
        case "blur": {
          const image = await props.utils.renderImageToOffScreenCanvas(
            videoFrame,
            "inputCanvas"
          );
          const segmentations = await processInput(image);
          if (props.status === "closed") {
            break;
          }
          if (props.outputCanvas.height !== height) {
            props.outputCanvas.width = width;
            props.outputCanvas.height = height;
          }
          await props.utils.drawBlurEffect(
            props.outputCanvas,
            image,
            segmentations,
            props.foregroundThreshold,
            props.backgroundBlurAmount,
            props.edgeBlurAmount,
            props.flipHorizontal
          );
          controller.enqueue(props.outputCanvas);
          break;
        }
        case "overlay": {
          if (!props.backgroundImage) {
            throw new Error(
              "Please call setBackgroundImage() method first"
            );
          }
          const image = await props.utils.renderImageToOffScreenCanvas(
            videoFrame,
            "inputCanvas"
          );
          const segmentations = await processInput(image);
          if (props.status === "closed") {
            break;
          }
          if (props.outputCanvas.height !== height) {
            props.outputCanvas.width = width;
            props.outputCanvas.height = height;
          }
          await props.utils.drawOverlayEffect(
            props.outputCanvas,
            image,
            props.backgroundImage,
            segmentations,
            props.foregroundThreshold,
            0,
            props.edgeBlurAmount,
            props.flipHorizontal
          );
          controller.enqueue(props.outputCanvas);
          break;
        }
        case "none": {
          controller.enqueue(videoFrame);
          break;
        }
      }
      props.status = "processing";
    },
    close: () => {
      if (!selfManageSegmenter) {
        segmenter.close();
      }
      props.outputCanvas = void 0;
      stopStreamTracks(props.outputStream);
      props.outputStream = void 0;
      props.status = "closed";
    },
    destroy: async () => {
      if (!selfManageSegmenter) {
        await segmenter.destroy();
      }
      props.status = "destroyed";
    }
  };
};

// src/processor.ts
var createMediaStreamTrackProcessor = (init) => {
  const processor = new MediaStreamTrackProcessor(init);
  return processor;
};

// src/generator.ts
var createMediaStreamTrackGenerator = (init) => {
  const generator = new MediaStreamTrackGenerator(init);
  return generator;
};

// src/transformer.ts
var createStreamTransformer = (transformer, writableStrategy, readableStrategy) => {
  const transformStream = new TransformStream(
    transformer,
    writableStrategy,
    readableStrategy
  );
  return transformStream;
};

// src/video/transformer.ts
var wrapTransformController = (videoFrame, controller) => {
  return {
    get desiredSize() {
      return controller.desiredSize;
    },
    enqueue: (frame) => {
      if (!frame) {
        return;
      }
      if (frame instanceof VideoFrame) {
        return controller.enqueue(frame);
      }
      if ("OffscreenCanvas" in window && frame instanceof OffscreenCanvas || frame instanceof HTMLCanvasElement || frame instanceof HTMLVideoElement || frame instanceof HTMLImageElement || frame instanceof ImageBitmap) {
        const timestamp = videoFrame.timestamp ?? 0;
        videoFrame.close();
        return controller.enqueue(
          new VideoFrame(frame, { timestamp, alpha: "discard" })
        );
      }
      throw new Error("Unexpected input frame");
    },
    error: (reason) => controller.error(reason),
    terminate: () => controller.terminate()
  };
};
var nullTransformController = (controller) => {
  return {
    get desiredSize() {
      return 0;
    },
    enqueue: (frame) => {
      if ("OffscreenCanvas" in window && frame instanceof OffscreenCanvas || frame instanceof HTMLCanvasElement || frame instanceof HTMLVideoElement || frame instanceof HTMLImageElement || frame instanceof ImageBitmap) {
        return controller.enqueue(frame);
      }
      throw new Error("Unexpected input frame");
    },
    error: (reason) => {
      throw new Error(reason);
    },
    terminate: () => controller.terminate()
  };
};
var adaptInputFrameTransformer = (transformer) => {
  const transform = (frame, controller) => {
    return transformer.transform?.(
      frame,
      wrapTransformController(frame, controller)
    );
  };
  return { ...transformer, transform };
};

// src/video/videoStreamTrackProcessor.ts
var createVideoTrackProcessor = () => (track, transformers, { signal } = {}) => {
  if (!transformers.length) {
    return Promise.resolve(track);
  }
  const processor = createMediaStreamTrackProcessor({ track });
  const trackGenerator = createMediaStreamTrackGenerator({ kind: "video" });
  let readable = processor.readable;
  transformers.forEach((transformer) => {
    readable = readable.pipeThrough(
      createStreamTransformer(
        adaptInputFrameTransformer(transformer)
      ),
      { signal }
    );
  });
  readable.pipeTo(trackGenerator.writable, {
    signal
  }).catch((error) => {
    if (signal && !(signal.aborted && (signal.reason === "close" /* Close */ || !("reason" in AbortSignal.prototype)))) {
      throw error;
    }
  });
  return Promise.resolve(trackGenerator);
};
var createVideoTrackProcessorWithFallback = ({
  width = PROCESSING_WIDTH,
  height = PROCESSING_HEIGHT,
  frameRate = FRAME_RATE
} = {}) => async (track, transformers, { signal } = {}) => {
  const [transformer] = transformers;
  if (!transformer?.transform) {
    return track;
  }
  if (transformers.length > 1) {
    throw new Error("Multi-transformer is NOT supported");
  }
  const outputCanvas = createCanvas(width, height);
  const ctx = getCanvasRenderingContext2D(outputCanvas, {
    desynchronized: true,
    alpha: false
  });
  const render = (input) => {
    if (!transformer.transform) {
      throw new Error("Transform is undefined");
    }
    return transformer.transform(
      input,
      nullTransformController({
        enqueue: (frame) => {
          if (!frame) {
            return;
          }
          const frameSize = getImageSize(frame);
          if (frameSize.height !== outputCanvas.height) {
            outputCanvas.height = frameSize.height;
            outputCanvas.width = frameSize.width;
          }
          ctx.drawImage(
            frame,
            0,
            0,
            frameSize.width,
            frameSize.height
          );
        },
        terminate: () => {
          stop();
        }
      })
    );
  };
  const runner = createFrameCallbackRequest(render, frameRate);
  const videoElement = toVideoElement(
    new MediaStream([track]),
    width,
    height
  );
  await playVideo(videoElement);
  await runner.start(videoElement);
  const stream = outputCanvas.captureStream(frameRate);
  const [trackGenerated] = stream.getVideoTracks();
  if (!trackGenerated) {
    throw new Error("Canvas captureStream returns no video track");
  }
  const stop = () => {
    stopStreamTracks(stream);
    runner.stop();
  };
  signal?.addEventListener("abort", stop);
  return trackGenerated;
};

// src/video/segmenters/mediapipe.ts
var createSegmenter = (basePath = "/", {
  modelType = "general",
  tfjsCoreLoaded = false,
  tfjsBackendLoaded = false,
  glueLoaded = false,
  processingWidth = PROCESSING_WIDTH,
  processingHeight = PROCESSING_HEIGHT,
  gluePath = "",
  selfieMode = false,
  prodMode = true
} = {}) => {
  const props = {
    selfieMode,
    segmentation: [],
    renderUtils: createCanvasRenderUtils(processingWidth, processingHeight),
    status: "created",
    tfjsCoreLoaded,
    tfjsBackendLoaded,
    glueLoaded
  };
  const toInputImage = createInputImageConvertor(
    (input) => props.renderUtils.drawAndBlurImageOnOffScreenCanvas(
      input,
      0,
      "blurredCanvas"
    )
  );
  const segmentPerson = async (input) => {
    const segmenter = ensure(props.segmenter);
    props.status = "processing";
    const image = await toInputImage(input);
    await segmenter.send({ image });
    props.status = "idle";
  };
  return {
    get model() {
      return "mediapipeSelfie";
    },
    get width() {
      return processingWidth;
    },
    get height() {
      return processingHeight;
    },
    get status() {
      return props.status;
    },
    open: async () => {
      props.status = "opening";
      if (!props.tfjsCoreLoaded) {
        await loadTfjsCore(prodMode);
        props.tfjsCoreLoaded = true;
      }
      if (!props.tfjsBackendLoaded) {
        await loadTfjsBackendWebGl();
        props.tfjsBackendLoaded = true;
      }
      if (!props.glueLoaded) {
        await loadScript(gluePath, gluePath);
        props.glueLoaded = true;
      }
      props.segmenter = new SelfieSegmentation({
        locateFile: (path) => `${basePath.replace(/\/+$/, "")}/${path}`
      });
      const modelSelection = modelType === "landscape" ? 1 : 0;
      props.segmenter.setOptions({
        modelSelection,
        selfieMode: props.selfieMode
      });
      props.segmenter.onResults((results) => {
        props.segmentation = [
          toSegmentation(
            results.segmentationMask,
            "canvasimagesource",
            () => "person"
          )
        ];
      });
      await props.segmenter.initialize();
      props.status = "opened";
    },
    process: async (input) => {
      await segmentPerson(input);
      return props.segmentation;
    },
    close: () => {
      props.segmenter?.reset();
      props.status = "closed";
    },
    destroy: async () => {
      props.status = "destroying";
      await props.segmenter?.close();
      props.status = "destroyed";
    }
  };
};

// src/video/typeGuards.ts
var isRenderEffects = (t) => {
  if (typeof t !== "string") {
    return false;
  }
  return RENDER_EFFECTS.some((effect) => effect === t);
};
var isSegmentationModel = (t) => {
  if (typeof t !== "string") {
    return false;
  }
  return SEG_MODELS.some((model) => model === t);
};

// src/path.ts
function toCoordinateString(p) {
  return p ? [p.x, p.y].join(",") : "";
}
function moveTo(p) {
  return p ? ["M", toCoordinateString(p)].join(" ") : "";
}
function lineTo(p) {
  return p ? ["L", toCoordinateString(p)].join(" ") : "";
}
function horizontalLineTo(x) {
  return `H ${x}`;
}
function verticalLineTo(y) {
  return `V ${y}`;
}
function cubicCurveTo({ scp, ecp, ep }) {
  return scp && ecp && ep ? ["C", ...[scp, ecp, ep].map(toCoordinateString)].join(" ") : "";
}
function closePath() {
  return "Z";
}

// src/visual.ts
var toPoint = (t) => t ? t : { x: 0, y: 0 };
function calculateDistance(p1, p2) {
  return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
}
function getBezierCurveControlPoints({
  p1,
  p2,
  p3,
  t
}) {
  const d12 = calculateDistance(p1, p2);
  const d23 = calculateDistance(p2, p3);
  const widthOfT = p3.x - p1.x;
  const heightOfT = p3.y - p1.y;
  const scaleA = t * d12 / (d12 + d23);
  const scaleB = t - scaleA;
  const cp12 = {
    x: p2.x - scaleA * widthOfT,
    y: p2.y - scaleA * heightOfT
  };
  const cp23 = {
    x: p2.x + scaleB * widthOfT,
    y: p2.y + scaleB * heightOfT
  };
  return [cp12, cp23];
}
var line = (data) => {
  if (data.length < 2) {
    return "";
  }
  const [start, ...rest] = data;
  return [moveTo(start), ...rest.map(lineTo)].join(" ");
};
var curve = (data) => {
  if (data.length <= 2) {
    return line(data);
  }
  const [start, ...rest] = data;
  const knots = rest.slice(0, -1);
  const [end] = rest.slice(-1);
  const tension = 1 / 2;
  const cps = knots.map((current, idx, pts) => {
    const prev = pts[idx - 1] || start;
    const nxt = pts[idx + 1] || end;
    return getBezierCurveControlPoints({
      p1: toPoint(prev),
      p2: current,
      p3: toPoint(nxt),
      t: tension
    });
  });
  const curveTo = (ep, idx) => {
    const [scp] = cps[idx - 1]?.slice(-1) || [start];
    const [ecp] = cps[idx] || [end];
    return cubicCurveTo({
      scp: toPoint(scp),
      ecp: toPoint(ecp),
      ep
    });
  };
  return [moveTo(start), ...rest.map(curveTo)].join(" ");
};
var closedCurve = ({ x, y }) => (data) => {
  return [
    curve(data),
    verticalLineTo(y),
    horizontalLineTo(x),
    closePath()
  ].join(" ");
};

// src/benchUtils.ts
var createBenchmark = (clock = performance, { calculationThresholdMS = 1e3 } = {}) => {
  const props = {
    beginTime: 0,
    endTime: 0,
    sumDelta: 0,
    count: 0,
    lastCalculateTime: 0,
    lastResult: 0
  };
  return {
    begin: () => {
      props.beginTime = clock.now();
    },
    end: () => {
      props.endTime = clock.now();
      props.sumDelta += props.endTime - props.beginTime;
      ++props.count;
    },
    calculateFps: () => {
      const calculateTime = clock.now();
      if (calculateTime - props.lastCalculateTime >= calculationThresholdMS) {
        const result = props.count === 0 ? 0 : props.sumDelta / props.count;
        props.sumDelta = 0;
        props.count = 0;
        props.lastCalculateTime = calculateTime;
        props.lastResult = result;
        return result;
      }
      return props.lastResult;
    }
  };
};
var calculateFps = (time) => time === 0 ? time : 1e3 / time;

// src/index.ts
var urls = {
  denoise: () => new URL("./worklets/denoise.worklet.js", import.meta.url)
};
export {
  AbortReason,
  BACKGROUND_BLUR_AMOUNT,
  CLIP_COUNT_THRESHOLD,
  CLIP_THRESHOLD,
  EDGE_BLUR_AMOUNT,
  FLIP_HORIZONTAL,
  FOREGROUND_THRESHOLD,
  FRAME_RATE,
  LOW_VOLUME_THRESHOLD,
  MONO_THRESHOLD,
  PROCESSING_HEIGHT,
  PROCESSING_WIDTH,
  RENDER_EFFECTS,
  SEG_MODELS,
  SILENT_THRESHOLD,
  VOICE_PROBABILITY_THRESHOLD,
  avg,
  calculateDistance,
  calculateFps,
  closedCurve,
  copyByteBufferToFloatBuffer,
  createAnalyzerGraphNode,
  createAnalyzerSubscribableGraphNode,
  createAsyncCallbackLoop,
  createAudioContext,
  createAudioDestinationGraphNode,
  createAudioGraph,
  createAudioGraphProxy,
  createAudioSignalDetector,
  createAudioStats,
  createBenchmark,
  createTransform as createCanvasTransform,
  createChannelMergerGraphNode,
  createChannelSplitterGraphNode,
  createDelayGraphNode,
  createDenoiseWorkletGraphNode,
  createFrameCallbackRequest,
  createGainGraphNode,
  createMediaElementSourceGraphNode,
  createSegmenter as createMediapipeSegmenter,
  createStreamDestinationGraphNode,
  createStreamSourceGraphNode,
  createVADetector,
  createVideoProcessor,
  createVideoTrackProcessor,
  createVideoTrackProcessorWithFallback,
  createVoiceDetectorFromProbability,
  createVoiceDetectorFromTimeData,
  curve,
  fitDestinationSize,
  fromByteToFloat,
  fromFloatToByte,
  getAudioStats,
  getBezierCurveControlPoints,
  isAnalyzerNodeInit,
  isAudioNode,
  isAudioNodeInit,
  isAudioParam,
  isClipping,
  isEqualSize,
  isLowVolume,
  isMono,
  isRenderEffects,
  isSegmentationModel,
  isSilent,
  isVoiceActivity,
  line,
  loadScript,
  loadTfjsBackendWebGl,
  loadTfjsCore,
  loadWasms,
  pow,
  processAverageVolume,
  resumeAudioOnInterruption,
  rms,
  round,
  subscribeTimeoutAnalyzerNode,
  subscribeWorkletNode,
  sum,
  toDecibel,
  urls
};
