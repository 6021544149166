import React, {useRef, useEffect} from 'react';
import cx from 'classnames';

import styles from './Video.module.scss';

export interface VideoElement extends HTMLVideoElement {
    setSinkId: (sinkId: string) => void;
}

/**
 * When the video element failed to run `play` as the purpose of resuming
 * a paused video element.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play#exceptions
 *
 * @param reason - Why did it fail
 */
export type FailedToResume = (reason: Error) => void;

/**
 * Resume a paused video element, and call `failedToPlay` when it failed to do
 * so
 *
 * @param video - video element
 * @param failedToPlay - a callback to be called when the play failed
 */
export const resume = (
    video: VideoElement | null,
    failedToPlay?: FailedToResume,
) => {
    if (video?.paused) {
        video.play().catch((reason: Error) => {
            failedToPlay?.(reason);
        });
    }
};

export const Video: React.FC<
    React.ComponentProps<'video'> & {
        srcObject?: MediaStream;
        sinkId?: string;
        captionsSrc?: string;
        captionsSrcLang?: string;
        autoPlay?: boolean;
        isMirrored?: boolean;
        playsInline?: boolean;
        textTrackKind?: 'captions' | 'subtitles';
        onPictureInPictureChange?: (isPip: boolean) => void;
        onFailedToResume?: FailedToResume;
    }
> = ({
    srcObject,
    sinkId,
    captionsSrc,
    captionsSrcLang,
    className,
    autoPlay = true,
    isMirrored = false,
    playsInline = true,
    textTrackKind,
    muted,
    onPictureInPictureChange,
    onFailedToResume,
    ...props
}) => {
    const videoRef = useRef<VideoElement>(null);

    useEffect(() => {
        const player = videoRef.current;
        let ignore = false;

        const onEnterPip = () => onPictureInPictureChange?.(true);
        const onLeavePip = () => {
            // Video element is not paused immediately right after event of 'leavepictureinpicture'
            // That's reason why we need to schedule a timeout function
            setTimeout(() => {
                if (ignore) {
                    return;
                }
                resume(player, onFailedToResume);
            }, 0);
            onPictureInPictureChange?.(false);
        };

        player?.addEventListener('enterpictureinpicture', onEnterPip);
        player?.addEventListener('leavepictureinpicture', onLeavePip);

        return () => {
            ignore = true;
            player?.removeEventListener('enterpictureinpicture', onEnterPip);
            player?.removeEventListener('leavepictureinpicture', onLeavePip);
            if (player && document.pictureInPictureElement === player) {
                void document.exitPictureInPicture?.().then(() => {
                    onPictureInPictureChange?.(false);
                });
            }
        };
    }, [videoRef, onPictureInPictureChange, onFailedToResume]);

    useEffect(() => {
        if (videoRef.current && srcObject) {
            videoRef.current.srcObject = srcObject;
        }
    }, [srcObject]);

    useEffect(() => {
        if (srcObject?.getAudioTracks().length) {
            videoRef?.current?.setSinkId?.(sinkId || '');
        }
    }, [sinkId, srcObject]);

    useEffect(() => {
        const resumeVideo = () => resume(videoRef.current, onFailedToResume);
        // Just need a track to subscribe the event
        const [track] = srcObject?.getTracks() ?? [];
        track?.addEventListener('unmute', resumeVideo);
        return () => {
            track?.removeEventListener('unmute', resumeVideo);
        };
    }, [srcObject, videoRef, onFailedToResume]);

    const muteOnCanPlay = () => {
        //FIXME: open react bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
        if (
            videoRef.current &&
            /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        ) {
            if (muted) {
                videoRef.current.muted = true;
                resume(videoRef.current);
            } else {
                videoRef.current.muted = false;
            }
        }
    };

    return (
        <video
            ref={videoRef}
            autoPlay={autoPlay}
            muted={muted}
            playsInline={playsInline}
            className={cx(
                {
                    [styles.mirrored]: isMirrored,
                },
                className,
            )}
            onCanPlay={muteOnCanPlay}
            {...props}
        >
            {textTrackKind && (
                <track
                    kind={textTrackKind}
                    src={captionsSrc}
                    srcLang={captionsSrcLang}
                    default
                />
            )}
        </video>
    );
};
