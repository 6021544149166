import {replace} from '../router';
import {infinityService} from '../services/InfinityClient.service';
import {EXPRESS_PATH, STEP_BY_STEP_PATH} from '../constants';
import {logger} from '../logger';
import {config} from '../config';
import {renderUserNamePageSignal} from '../signals/MeetingFlow.signals';

const decodeURIPart = (part: string) =>
    decodeURIComponent(part.replace(/\+/g, ' '));

export const parseLegacyParams = (legacyParams: string) => {
    try {
        const params = new URLSearchParams(legacyParams);
        const bandwidth = params.get('bandwidth');
        const conference = params.get('conference');
        const extension = params.get('extension');
        const join = params.get('join');
        const muteCamera = params.get('muteCamera');
        const muteMicrophone = params.get('muteMicrophone');
        const name = decodeURIPart(params.get('name') ?? '');
        const pin = params.get('pin');
        const role = params.get('role');

        const isGuest = role === 'guest';

        if (name) {
            renderUserNamePageSignal.emit(false);
            config.set({key: 'displayName', value: name});
        }

        if (bandwidth) {
            config.set({key: 'bandwidth', value: bandwidth});
        }

        if (muteCamera) {
            config.set({key: 'isVideoInputMuted', value: Boolean(muteCamera)});
        }

        if (muteMicrophone) {
            config.set({
                key: 'isAudioInputMuted',
                value: Boolean(muteMicrophone),
            });
        }

        if (conference) {
            replace(
                `/m/${conference}${
                    // Maybe automatically redirect to step by step if guest?
                    join
                        ? ''
                        : isGuest
                        ? '/' + STEP_BY_STEP_PATH
                        : '/' + EXPRESS_PATH
                }`,
            );
        }

        if (pin || isGuest) {
            // <role> is guest if you want to allow Guests to automatically join a conference that allows Guests
            infinityService.setPin(pin ? pin : undefined);
        }

        if (extension) {
            infinityService.setConferenceExtension(extension);
        }
    } catch (reason) {
        logger.debug({reason}, `Can't parse the legacy params`);
    }
};
