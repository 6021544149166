// src/types.ts
var RecoveryTimeout = /* @__PURE__ */ ((RecoveryTimeout2) => {
  RecoveryTimeout2[RecoveryTimeout2["ConnectionState"] = 5e3] = "ConnectionState";
  RecoveryTimeout2[RecoveryTimeout2["IceConnectionState"] = 2e3] = "IceConnectionState";
  return RecoveryTimeout2;
})(RecoveryTimeout || {});

// src/utils.ts
import { createSignal } from "@pexip/signal";
import log from "@pexip/logger";

// src/sdpManager.ts
import * as sdpTransform from "sdp-transform";
var TWCCExtensionUrl = "http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions";
var SdpTransformManager = class {
  constructor(sdp, enrichOptions) {
    this.sdp = sdp;
    this.setSdp(sdp, enrichOptions);
  }
  setSdp(sdp, enrichOptions) {
    this.sdp = sdp;
    if (enrichOptions) {
      this.enrichSdp(enrichOptions);
    }
  }
  getSdp() {
    return this.sdp;
  }
  getFingerprints() {
    if (!this.sdp?.sdp) {
      return [];
    }
    const transformSdp = sdpTransform.parse(this.sdp.sdp);
    return [
      transformSdp.fingerprint,
      ...transformSdp.media.map((m) => m.fingerprint)
    ].flatMap((fingerprint) => fingerprint ? [fingerprint] : []);
  }
  enrichSdp(options) {
    let modifiedSdp = this.sdp;
    if (this.sdp?.sdp) {
      let transformSdp = sdpTransform.parse(this.sdp.sdp);
      if (options.contentSlides) {
        transformSdp = this.addVideoSlidesContentLine(transformSdp);
      }
      if (options.videoAS) {
        transformSdp = this.addBandwidthLine(
          transformSdp,
          options.videoAS,
          options.videoTIAS
        );
      }
      if (options.vp9Disabled) {
        transformSdp = this.stripCodecs(transformSdp, ["VP9" /* VP9 */]);
      }
      if (this.shouldAddSupportForHighQualityStream(options)) {
        transformSdp = this.addSupportForHighQualityStream(
          transformSdp,
          options.allow4kPreso
        );
      }
      if (options.packetizationMode) {
        transformSdp = this.removeFmtpWithoutPacketization(transformSdp);
      }
      modifiedSdp = {
        sdp: sdpTransform.write(transformSdp),
        type: this.sdp.type
      };
    }
    this.sdp = modifiedSdp;
  }
  isTWCCsupported = () => this.sdp.sdp?.includes(TWCCExtensionUrl);
  addMsidToMline = (mid, msid) => {
    if (!this.sdp.sdp) {
      return;
    }
    let modifiedSdp = this.sdp;
    const transformSdp = sdpTransform.parse(this.sdp.sdp);
    const mLine = transformSdp.media.find(
      ({ mid: currentMid }) => String(currentMid) === mid
    );
    if (mLine) {
      const msids = mLine.msid?.split(" ");
      if (msids?.[0] === "-") {
        msids[0] = msid;
      }
      mLine.msid = msids?.join(" ");
    }
    modifiedSdp = {
      sdp: sdpTransform.write(transformSdp),
      type: this.sdp.type
    };
    this.sdp = modifiedSdp;
  };
  shouldAddSupportForHighQualityStream(options) {
    return options.contentSlides || options?.allow1080p && options.videoAS && options.videoAS >= 2564;
  }
  addVideoSlidesContentLine(sdp) {
    const videoLine = this.getLastVideoLine(sdp.media);
    if (videoLine) {
      videoLine.content = "slides";
    }
    return sdp;
  }
  addBandwidthLine(sdp, videoAS, videoTIAS) {
    const videoLines = this.getVideoLines(sdp.media);
    videoLines.forEach((videoLine) => {
      if (!videoLine.bandwidth) {
        videoLine.bandwidth = [];
      }
      videoLine.bandwidth.push({
        type: "AS",
        limit: videoAS
      });
      if (videoTIAS) {
        videoLine.bandwidth.push({
          type: "TIAS",
          limit: videoTIAS
        });
      }
    });
    return sdp;
  }
  addSupportForHighQualityStream(sdp, allow4kPreso = false) {
    const videoLines = this.getVideoLines(sdp.media);
    videoLines.forEach((videoLine) => {
      const codecs = this.getCodecs(videoLine.rtp);
      videoLine.fmtp = videoLine.fmtp.map((fmtp) => {
        if (fmtp.config.includes("max-fs")) {
          return fmtp;
        }
        const codec = codecs[fmtp.payload];
        const is4kPreso = isPreso(videoLine) && allow4kPreso;
        if (codec === "VP8" /* VP8 */ || codec === "VP9" /* VP9 */) {
          fmtp.config += this.getVPXConfigOverrides(is4kPreso);
        } else if (codec === "H264" /* H264 */) {
          fmtp.config += this.getH264ConfigOverrides(is4kPreso);
        }
        return fmtp;
      });
    });
    return sdp;
  }
  getVPXConfigOverrides(is4kEnabled = false) {
    return `;max-fs=${is4kEnabled ? "36864" : "8160"};max-fr=30`;
  }
  getH264ConfigOverrides(is4kEnabled = false) {
    return is4kEnabled ? ";max-br=32768;max-mbps=2073600;max-fs=36864;max-smbps=2073600;max-fps=6000;max-fr=30" : ";max-br=3732;max-mbps=245760;max-fs=8192;max-smbps=245760;max-fps=3000;max-fr=30";
  }
  removeFmtpWithoutPacketization(sdp) {
    const videoLines = this.getVideoLines(sdp.media);
    videoLines.forEach((videoLine) => {
      const codecs = this.getCodecs(videoLine.rtp);
      let payloadTypes = [];
      for (const fmtp of videoLine.fmtp) {
        if (codecs[fmtp.payload] !== "H264" /* H264 */) {
          continue;
        }
        const params = fmtp.config.split(";");
        const [, profile] = params.find((param) => param.includes("profile-level-id"))?.split("=") ?? [];
        const profileIdc = profile?.substring(0, 2);
        const profileIop = profile?.substring(2, 4) ?? "";
        if (profileIdc !== "42") {
          continue;
        }
        if (parseInt("0x" + profileIop) & 64 && fmtp.config.includes("packetization-mode=1")) {
          payloadTypes = [fmtp.payload];
          break;
        }
        payloadTypes.push(fmtp.payload);
      }
      videoLine.rtp = videoLine.rtp.filter(
        ({ codec, payload }) => codec !== "H264" /* H264 */ || codec === "H264" /* H264 */ && payloadTypes.includes(payload)
      );
      videoLine.fmtp = videoLine.fmtp.filter(({ payload, config }) => {
        if (codecs[payload] === "H264" /* H264 */ && !payloadTypes.includes(payload)) {
          return false;
        }
        const [name, value] = config.split("=");
        if (name === "apt" && value && codecs[value] === "H264" /* H264 */ && !payloadTypes.includes(Number(value))) {
          return false;
        }
        return true;
      });
      if (videoLine.rtcpFb) {
        videoLine.rtcpFb = videoLine.rtcpFb.filter(
          ({ payload }) => codecs[payload] !== "H264" /* H264 */ || codecs[payload] === "H264" /* H264 */ && payloadTypes.includes(payload)
        );
      }
    });
    return sdp;
  }
  stripCodecs(sdp, disableCodecs) {
    const videoLines = this.getVideoLines(sdp.media);
    for (const videoLine of videoLines) {
      if (videoLine) {
        const removePayloads = videoLine.rtp.filter(({ codec }) => disableCodecs.includes(codec)).map(({ payload }) => payload);
        if (removePayloads.length > 0) {
          const rtxApts = removePayloads.map((item) => `apt=${item}`);
          const rtxPayloads = videoLine.fmtp.filter(
            (item) => rtxApts.includes(item.config)
          );
          removePayloads.push(
            ...rtxPayloads.map((item) => item.payload)
          );
        }
        if (videoLine.payloads) {
          for (const payload of removePayloads) {
            videoLine.payloads = videoLine.payloads.replace(
              `${payload} `,
              ""
            );
          }
        }
        videoLine.rtp = videoLine.rtp.filter(
          (rtp) => !removePayloads.includes(rtp.payload)
        );
        videoLine.fmtp = videoLine.fmtp.filter(
          (fmtp) => !removePayloads.includes(fmtp.payload)
        );
        if (videoLine.rtcpFb) {
          videoLine.rtcpFb = videoLine.rtcpFb.filter(
            (rtcpFb) => !removePayloads.includes(rtcpFb.payload)
          );
        }
      }
    }
    return sdp;
  }
  getVideoLines(media) {
    return media.filter((line) => line.type === "video");
  }
  getVideoLine(media) {
    return media.find((line) => line.type === "video");
  }
  getLastVideoLine(media) {
    let index;
    media.forEach((line, i) => {
      if (line.type === "video") {
        index = i;
      }
    });
    return typeof index !== "undefined" ? media[index] : void 0;
  }
  getCodecs(rtp) {
    return rtp.reduce((acc, { codec, payload }) => {
      acc[payload] = codec;
      return acc;
    }, {});
  }
};
var hasICECandidates = (sdp) => {
  if (!sdp) {
    return false;
  }
  const transformedSDP = sdpTransform.parse(sdp);
  return transformedSDP.media.some(
    (m) => m.candidates && m.candidates.length > 0
  );
};
var getMediaLines = (sdp) => {
  if (!sdp) {
    return [];
  }
  return sdpTransform.parse(sdp).media;
};
var isPreso = (media) => media.content === "slides";

// src/utils.ts
var logger = log.child({ name: "peer-connection" });
var createRefsLog = (getRefs) => {
  const info = (obj) => {
    const refs = getRefs();
    return { ...refs, ...obj };
  };
  return {
    debug: (msg, obj) => logger.debug(info(obj), msg),
    info: (msg, obj) => logger.info(info(obj), msg),
    error: (msg, obj) => logger.error(info(obj), msg),
    warn: (msg, obj) => logger.warn(info(obj), msg)
  };
};
var getPeerConnectionStates = (pc) => ({
  get connectionState() {
    return pc.connectionState;
  },
  get iceConnectionState() {
    return pc.iceConnectionState;
  },
  get iceGatheringState() {
    return pc.iceGatheringState;
  },
  get signalingState() {
    return pc.signalingState;
  }
});
var getStatesAndProps = (pc) => {
  return {
    ...getPeerConnectionStates(pc),
    offerOptions: pc.offerOptions,
    answerOptions: pc.answerOptions
  };
};
var logReferences = (refs) => ({
  module: refs.module,
  references: refs
});
var createGetRefs = (pc) => () => ({
  ...logReferences(pc.references),
  ...getStatesAndProps(pc)
});
var isSameStream = (stream1, stream2) => {
  return stream1 && stream2 && stream1.id === stream2.id;
};
var handleOnTrack = (event, currentRemoteStreams) => {
  const remoteStreams = event.streams.filter(
    (newStream) => !currentRemoteStreams.find(
      (stream) => isSameStream(newStream, stream)
    )
  );
  return [remoteStreams, [...event.streams.length ? [] : [event.track]]];
};
var wirePeerConnectionEventHandler = ({
  key,
  pc,
  signal
}) => {
  const log2 = createRefsLog(createGetRefs(pc));
  switch (key) {
    case "onConnectionStateChange":
      pc.onConnectionStateChange = (event) => {
        log2.info("onConnectionStateChange emitted", {
          event
        });
        signal.emit(pc.connectionState);
      };
      break;
    case "onDataChannel":
      pc.onDataChannel = (event) => {
        log2.info("onDataChannel emitted", {
          event
        });
        signal.emit(event.channel);
      };
      break;
    case "onIceCandidate":
      pc.onIceCandidate = (event) => {
        log2.info("onIceCandidate emitted", {
          event
        });
        signal.emit(event.candidate);
      };
      break;
    case "onIceCandidateError":
      pc.onIceCandidateError = (event) => {
        log2.info("onIceCandidateError emitted", {
          event
        });
        signal.emit(event);
      };
      break;
    case "onIceConnectionStateChange":
      pc.onIceConnectionStateChange = (event) => {
        log2.info("onIceConnectionStateChange emitted", {
          event
        });
        signal.emit(pc.iceConnectionState);
      };
      break;
    case "onIceGatheringStateChange":
      pc.onIceGatheringStateChange = (event) => {
        log2.info("onIceGatheringStateChange emitted", {
          event
        });
        signal.emit(pc.iceGatheringState);
      };
      break;
    case "onNegotiationNeeded":
      pc.onNegotiationNeeded = () => {
        log2.info("onNegotiationNeeded emitted");
        signal.emit();
      };
      break;
    case "onSignalingStateChange":
      pc.onSignalingStateChange = (event) => {
        log2.info("onSignalingStateChange emitted", {
          event
        });
        signal.emit(pc.signalingState);
      };
      break;
    case "onTrack":
      pc.onTrack = (event) => {
        log2.info("onTrack emitted", {
          event
        });
        signal.emit(event);
      };
      break;
    case "onRemoteStreams":
      pc.onRemoteStreams = (streams) => {
        log2.info("onRemoteStreams emitted", {
          remoteStreams: streams
        });
        signal.emit(streams);
      };
      break;
    case "onTransceiverChange":
      pc.onTransceiverChange = () => {
        log2.info("onTransceiverChanged");
        signal.emit();
      };
      break;
    case "onSecureCheckCode":
      pc.onSecureCheckCode = (secureCheckCode) => {
        log2.info("onSecureCheckCode emitted", { secureCheckCode });
        signal.emit(secureCheckCode);
      };
      break;
  }
};
var wirePeerConnectionEvents = (pc, signals) => {
  Object.keys(signals).forEach((eventKey) => {
    const signalKey = eventKey;
    const signal = signals[signalKey];
    if (signal) {
      wirePeerConnectionEventHandler({
        key: signalKey,
        pc,
        signal
      });
    }
  });
};
var createPCSignal = (name, crucial = true) => createSignal({
  name: `call:peerConnection:${name}`,
  allowEmittingWithoutObserver: !crucial
});
var REQUIRED_SIGNAL_KEYS = [
  "onOfferRequired",
  "onReceiveAnswer",
  "onReceiveOffer",
  "onOffer",
  "onAnswer",
  "onError"
];
var createPCSignals = (more, scope = "") => {
  const signalScope = scope && [scope, ":"].join("");
  return [...REQUIRED_SIGNAL_KEYS, ...more].reduce(
    (signals, key) => ({
      ...signals,
      [key]: createPCSignal(`${signalScope}${key}`)
    }),
    {}
  );
};
var withSignals = (peer) => ({
  onReceiveAnswer,
  onReceiveIceCandidate,
  onReceiveOffer,
  onOffer,
  onAnswer,
  onError,
  onNegotiationNeeded = createPCSignal("onNegotiationNeeded"),
  onIceCandidateError = createPCSignal(
    "onIceCandidateError"
  ),
  onIceGatheringStateChange = createPCSignal(
    "onIceGatheringStateChange"
  ),
  ...pcEventSignals
}) => {
  pcEventSignals && wirePeerConnectionEvents(peer, {
    ...pcEventSignals,
    onNegotiationNeeded,
    onIceCandidateError,
    onIceGatheringStateChange
  });
  const log2 = createRefsLog(createGetRefs(peer));
  let waitForICEGatheringComplete = false;
  const emitError = (msg, context) => {
    log2.error(msg, { ...context, ...getPeerConnectionStates(peer) });
    if (context?.error instanceof Error) {
      onError.emit(context.error);
    }
  };
  const emitLocalDescription = (sdp) => {
    const localDescription = sdp ?? peer.pendingLocalDescription;
    if (shouldEmitLocalDescription(localDescription)) {
      switch (localDescription?.type) {
        case "offer":
          onOffer.emit(localDescription);
          break;
        case "answer":
          onAnswer.emit(localDescription);
          break;
        default:
          log2.error(
            'Attempt to emit localDescription other than "answer" and "offer"',
            { localDescription }
          );
          throw new Error("Unknown SDP type");
      }
    }
  };
  const shouldEmitLocalDescription = (localDescription) => {
    if (localDescription?.sdp) {
      if (pcEventSignals.onIceCandidate || hasICECandidates(localDescription.sdp) || peer.iceGatheringState === "complete") {
        return true;
      } else {
        waitForICEGatheringComplete = true;
        return false;
      }
    }
    logger.error("Local Description or SDP inside is undefined", {
      localDescription
    });
    return false;
  };
  const createOffer = async () => {
    try {
      const offer = await peer.createOffer();
      log2.info("emit offer", { offer });
      emitLocalDescription(offer);
    } catch (error) {
      emitError("createOffer", { error });
    }
  };
  const responseOffer = async (offer) => {
    try {
      log2.info("handle receiveOffer signal", { offer });
      await peer.receiveOffer(offer);
      const answer = await peer.createAnswer();
      emitLocalDescription(answer);
    } catch (error) {
      emitError("receiveOffer/createAnswer", { error, offer });
    }
  };
  const signalSubscriptions = [
    onIceGatheringStateChange.add((iceGatheringState) => {
      if (iceGatheringState === "complete" && waitForICEGatheringComplete) {
        emitLocalDescription();
        waitForICEGatheringComplete = false;
      }
    }),
    onNegotiationNeeded.add(() => {
      log2.info("handle onNegotiationNeeded signal");
      void createOffer();
    }),
    onIceCandidateError.add((error) => {
      emitError("onIceCandidateError", {
        error: new Error(error.errorText),
        event: error
      });
    }),
    onReceiveIceCandidate?.add((candidate) => {
      log2.info("handle onReceiveIceCandidate signal", { candidate });
      peer.receiveIceCandidate(candidate).catch((error) => {
        emitError("receiveIceCandidate", { error, candidate });
      });
    }),
    onReceiveOffer.add((offer) => {
      void responseOffer(offer);
    }),
    onReceiveAnswer.add((answer) => {
      log2.info("handle receiveAnswer signal", { answer });
      peer.receiveAnswer(answer).catch((error) => {
        emitError("receiveAnswer", { error, answer });
      });
    })
  ].flatMap((a) => a ? [a] : []);
  return signalSubscriptions;
};
var getCreateLoopbackConnectionFn = (rtcConnection = new RTCPeerConnection(), rtcLoopbackConnection = new RTCPeerConnection(), loopbackStream = new MediaStream()) => async (stream) => {
  rtcConnection.onicecandidate = (e) => e.candidate && rtcLoopbackConnection.addIceCandidate(
    new RTCIceCandidate(e.candidate)
  );
  rtcLoopbackConnection.onicecandidate = (e) => e.candidate && rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
  rtcLoopbackConnection.ontrack = (e) => {
    if (e.streams[0]) {
      return e.streams[0].getTracks().forEach((track) => loopbackStream.addTrack(track));
    }
  };
  stream.getTracks().forEach(function(track) {
    rtcConnection.addTrack(track, stream);
  });
  const offer = await rtcConnection.createOffer();
  await rtcConnection.setLocalDescription(offer);
  await rtcLoopbackConnection.setRemoteDescription(offer);
  const answer = await rtcLoopbackConnection.createAnswer();
  await rtcLoopbackConnection.setLocalDescription(answer);
  await rtcConnection.setRemoteDescription(answer);
  return loopbackStream;
};

// src/constants.ts
var TRANSCEIVER_MEDIA_TYPES = ["audio", "video"];
var TRANSCEIVER_CONTENT_TYPES = ["main", "preso"];

// src/peerConnections.ts
function createPeerConnection(options = {}, peerConnection) {
  const props = {
    bandwidth: options.bandwidth ?? 0,
    contentSlides: Boolean(options.transceiversDirection?.preso?.video),
    offerOptions: options.offerOptions,
    answerOptions: options.answerOptions,
    references: {
      module: "PeerConnection",
      createdAt: new Date().toISOString()
    },
    packetizationMode: Boolean(options.packetizationMode),
    iceRestartNeeded: false,
    negotiationNeeded: false,
    negotiationInProgress: false,
    pendingOffer: void 0,
    transceiversDirection: options.transceiversDirection,
    vp9Disabled: options.vp9Disabled ?? false,
    allow1080p: options.allow1080p,
    allow4kPreso: options.allow4kPreso,
    currentRemoteDescription: void 0,
    localFingerprints: [],
    remoteFingerprints: []
  };
  const mainTranseivers = new Proxy({}, {
    set: (target, p, value) => {
      const didUpdate = Reflect.set(target, p, value);
      if (didUpdate) {
        eventHandlers.onTransceiverChange?.();
      }
      return didUpdate;
    }
  });
  const presoTranseivers = new Proxy({}, {
    set: (target, p, value) => {
      const didUpdate = Reflect.set(target, p, value);
      if (didUpdate) {
        eventHandlers.onTransceiverChange?.();
      }
      return didUpdate;
    }
  });
  const transceivers = {
    main: mainTranseivers,
    preso: presoTranseivers
  };
  let localMainStream;
  let localPresentationStream;
  let remoteStreams = [];
  let remoteStreamlessTracks = [];
  let remoteMediaLines = [];
  const timers = {};
  const clearTimer = (timerKey, onDone) => {
    if (timers[timerKey]) {
      clearTimeout(timers[timerKey]);
      timers[timerKey] = void 0;
      onDone?.();
    }
  };
  const eventHandlers = {};
  const peer = peerConnection ?? new RTCPeerConnection(options.rtcConfig);
  if (typeof peer.peerIdentity === "object") {
    peer.peerIdentity.catch((err) => {
      logger.error(err);
      peer.close();
    });
  }
  const log2 = createRefsLog(() => ({
    ...logReferences(props.references),
    ...getPeerConnectionStates(peer),
    offerOptions: props.offerOptions,
    answerOptions: props.answerOptions
  }));
  const syncSenderTrack = (stream, contentType = "main") => async (sender, track) => {
    const localStream = contentType === "main" ? localMainStream : localPresentationStream;
    log2.debug("syncSenderTrack", {
      contentType,
      stream,
      sender,
      track,
      localStream
    });
    if (track) {
      if (sender) {
        log2.debug("replaceTrack", {
          stream,
          sender,
          track,
          localStream
        });
        sender.setStreams?.(stream);
        return await sender.replaceTrack(track);
      }
      log2.debug("addTransceiver/addTrack", {
        stream,
        sender,
        track,
        localStream
      });
      const transceiverType = track.kind;
      transceivers[contentType][transceiverType] = peer.addTransceiver(track, {
        streams: [stream],
        direction: props.transceiversDirection?.[contentType]?.[transceiverType]
      });
      return;
    }
    if (sender) {
      log2.debug("removeTrack", {
        stream,
        sender,
        track,
        localStream
      });
      if (localStream && sender.track) {
        localStream.removeTrack(sender.track);
      }
      return peer.removeTrack(sender);
    }
    log2.warn("[syncSenderTrack] both `sender` and `track` are falsy", {
      stream,
      sender,
      track,
      localStream
    });
  };
  const setLocalStream = async (mediaStream, contentType = "main") => {
    log2.info("call setLocalStream", { mediaStream, contentType });
    if (mediaStream.getTracks().some((track) => track.readyState === "live")) {
      if (contentType === "main") {
        localMainStream = mediaStream;
      }
      if (contentType === "preso") {
        localPresentationStream = mediaStream;
      }
      const syncTrack = syncSenderTrack(mediaStream, contentType);
      const [newAudioTrack] = mediaStream.getAudioTracks();
      const [newVideoTrack] = mediaStream.getVideoTracks();
      if (props.transceiversDirection?.[contentType]?.audio) {
        log2.info("Sync Audio Track", { contentType });
        await syncTrack(
          transceivers[contentType].audio?.sender,
          newAudioTrack
        );
      }
      log2.info("Sync Video Track", { contentType });
      await syncTrack(
        transceivers[contentType].video?.sender,
        newVideoTrack
      );
    } else {
      log2.warn(
        "[setLocalStream] No track or no track is live from the stream",
        { stream: mediaStream, contentType }
      );
    }
  };
  const isAllowedToNegotiate = () => {
    if (props.negotiationInProgress) {
      log2.info("Negotiation in progress. Ignoring negotiation request");
      return false;
    }
    return true;
  };
  const negotiate = () => {
    if (!isAllowedToNegotiate()) {
      return;
    }
    props.negotiationInProgress = true;
    if (eventHandlers.negotiationNeeded) {
      if (peer.signalingState === "stable") {
        log2.info("[negotiate] trigger negotiationneeded manually");
        eventHandlers.negotiationNeeded();
        props.negotiationNeeded = false;
      } else {
        props.negotiationNeeded = true;
        log2.info("[negotiate] defer manual negotiation trigger");
      }
    } else {
      log2.warn(
        "[negotiate] try to negotiate but no negotiationneeded handler"
      );
    }
  };
  const fallbackRestartIce = () => {
    log2.info("[restartIce fallback] trigger negotiationneeded manually");
    props.offerOptions = { ...props.offerOptions, iceRestart: true };
    props.iceRestartNeeded = false;
    negotiate();
  };
  const restartIce = () => {
    if (!eventHandlers.onIceCandidate) {
      return;
    }
    clearTimer("connectionState", () => {
      log2.debug("clears connectionStateTimer due to iceRestart");
    });
    if (peer.restartIce !== void 0) {
      log2.info("restartIce");
      peer.restartIce();
    } else {
      if (peer.signalingState === "stable") {
        log2.info("restartIce fallback");
        fallbackRestartIce();
      } else {
        props.iceRestartNeeded = true;
        log2.info("restartIce fallback deferred");
      }
    }
  };
  const calculateSecureCheckCode = async () => {
    log2.info("calculateSecureCheckCode");
    if (props.localFingerprints.length && props.remoteFingerprints.length) {
      const local = props.localFingerprints.map(({ type, hash }) => type + hash).sort().join("");
      const remote = props.remoteFingerprints.map(({ type, hash }) => type + hash).sort().join("");
      const msg = [local, remote].sort().join("");
      const msgBuffer = new TextEncoder().encode(msg);
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
      eventHandlers.onSecureCheckCode?.(hashHex);
    }
  };
  const isRemoteMainTrack = (transceivers2 = peer.getTransceivers()) => (track) => transceivers2.some((transceiver) => {
    const media = remoteMediaLines.find(
      (media2) => !isPreso(media2) && String(media2.mid) === transceiver.mid
    );
    if (!media) {
      return false;
    }
    return transceiver.receiver.track.kind === track.kind && transceiver.receiver.track.id === track.id;
  });
  peer.oniceconnectionstatechange = (event) => {
    log2.info("oniceconnectionstatechange handler", { event });
    clearTimer("iceConnectionState", () => {
      log2.debug("clears the restart ice timer", { event });
    });
    if (peer.iceConnectionState === "failed") {
      log2.debug("restarts ice because it went to failed", { event });
      restartIce();
    } else if (peer.iceConnectionState === "disconnected") {
      log2.debug("scheduls ice restart because it went to disconnected", {
        event
      });
      timers.iceConnectionState = window.setTimeout(
        restartIce,
        2e3 /* IceConnectionState */
      );
    }
    if (eventHandlers.iceConnectionStateChange) {
      eventHandlers.iceConnectionStateChange(event);
    }
  };
  peer.onsignalingstatechange = (event) => {
    log2.info("onsignalingstatechange handler", { event });
    if (peer.signalingState === "stable") {
      if (props.iceRestartNeeded && eventHandlers.onIceCandidate) {
        fallbackRestartIce();
      } else if (props.negotiationNeeded) {
        negotiate();
      }
    }
    if (eventHandlers.signalingStateChange) {
      eventHandlers.signalingStateChange(event);
    }
  };
  peer.onicecandidate = (event) => {
    if (!eventHandlers.onIceCandidate) {
      return;
    }
    log2.info("onicecandidate handler", { event });
    eventHandlers.onIceCandidate(event);
  };
  peer.onnegotiationneeded = () => {
    log2.info("onnegotiationneeded handler");
    if (!isAllowedToNegotiate()) {
      return;
    }
    props.negotiationInProgress = true;
    if (eventHandlers.negotiationNeeded) {
      eventHandlers.negotiationNeeded();
    }
  };
  peer.ontrack = (event) => {
    const [newRemoteStreams, newRemoteStreamlessTracks] = handleOnTrack(
      event,
      remoteStreams
    );
    log2.info("ontrack", {
      event,
      newRemoteStreams,
      newRemoteStreamlessTracks,
      remoteStreams,
      remoteStreamlessTracks,
      remoteMediaLines
    });
    if (newRemoteStreams.length) {
      remoteStreams = [...remoteStreams, ...newRemoteStreams];
      log2.debug("new remote streams", { remoteStreams });
      eventHandlers.onRemoteStreams?.(remoteStreams);
    }
    if (newRemoteStreamlessTracks.length) {
      remoteStreamlessTracks = [
        ...remoteStreamlessTracks,
        ...newRemoteStreamlessTracks
      ];
      if ([
        ...remoteStreams.flatMap((stream) => stream.getTracks()),
        ...remoteStreamlessTracks
      ].length === remoteMediaLines.length) {
        remoteStreams = [
          ...remoteStreams,
          new MediaStream(remoteStreamlessTracks)
        ];
        log2.debug("new remote streams", { remoteStreams });
        eventHandlers.onRemoteStreams?.(remoteStreams);
      }
    }
    eventHandlers.onTrack?.(event);
  };
  return {
    get iceGatheringState() {
      return peer.iceGatheringState;
    },
    get iceConnectionState() {
      return peer.iceConnectionState;
    },
    get signalingState() {
      return peer.signalingState;
    },
    get connectionState() {
      return peer.connectionState;
    },
    get localStream() {
      return localMainStream;
    },
    get remoteStreams() {
      return remoteStreams;
    },
    get senders() {
      return peer.getSenders();
    },
    get receivers() {
      return peer.getReceivers();
    },
    getTransceiver(type, contentType = "main") {
      return transceivers[contentType][type];
    },
    get hasICECandidates() {
      return hasICECandidates(
        peer.pendingLocalDescription?.sdp ?? peer.currentLocalDescription?.sdp
      );
    },
    get currentLocalDescription() {
      if (peer.currentLocalDescription !== void 0) {
        return peer.currentLocalDescription;
      }
      return peer.localDescription;
    },
    get pendingLocalDescription() {
      if (peer.pendingLocalDescription !== void 0) {
        return peer.pendingLocalDescription || void 0;
      }
      return peer.signalingState === "stable" || peer.localDescription === null ? void 0 : peer.localDescription;
    },
    get currentRemoteDescription() {
      if (peer.currentRemoteDescription !== void 0) {
        return peer.currentRemoteDescription;
      }
      return peer.remoteDescription;
    },
    get pendingRemoteDescription() {
      if (peer.pendingRemoteDescription !== void 0) {
        return peer.pendingRemoteDescription;
      }
      return peer.signalingState === "stable" ? null : peer.remoteDescription;
    },
    get bandwidth() {
      return props.bandwidth;
    },
    get references() {
      return props.references;
    },
    get offerOptions() {
      return props.offerOptions;
    },
    set offerOptions(newOptions) {
      props.offerOptions = { ...props.offerOptions, ...newOptions };
    },
    get answerOptions() {
      return props.answerOptions;
    },
    set answerOptions(newOptions) {
      log2.info("set answerOptions", {
        newOptions,
        answerOptions: props.answerOptions
      });
      props.answerOptions = { ...props.answerOptions, ...newOptions };
    },
    set bandwidth(bandwidth) {
      log2.info("set bandwidth", {
        newBandwidth: bandwidth,
        bandwidth: props.bandwidth
      });
      if (props.bandwidth !== bandwidth) {
        props.bandwidth = bandwidth;
        restartIce();
      }
    },
    get contentSlides() {
      return props.contentSlides;
    },
    set contentSlides(contentSlides) {
      log2.info("set contentSlides", {
        newContentSlides: contentSlides,
        contentSlides: props.contentSlides
      });
      props.contentSlides = contentSlides;
    },
    set negotiationNeeded(needed) {
      if (needed) {
        negotiate();
      }
    },
    getStats: async (selector) => await peer.getStats(selector),
    setLocalStream,
    setReference(key, value) {
      log2.info("call setReference", {
        references: props.references,
        key,
        value
      });
      props.references[key] = value;
    },
    createDataChannel: (label, dataChannelDict) => {
      log2.info("call createDataChannel", { label, dataChannelDict });
      return peer.createDataChannel(label, dataChannelDict);
    },
    createOffer: async (offerOptions) => {
      log2.info("call createOffer", { param: offerOptions });
      TRANSCEIVER_CONTENT_TYPES.forEach(
        (contentType) => TRANSCEIVER_MEDIA_TYPES.forEach((mediaType) => {
          const direction = props.transceiversDirection?.[contentType]?.[mediaType];
          if (!transceivers[contentType][mediaType] && direction) {
            transceivers[contentType][mediaType] = peer.addTransceiver(mediaType, {
              direction
            });
          }
        })
      );
      const sdp = await peer.createOffer({
        ...props.offerOptions,
        ...offerOptions
      });
      const sdpManager = new SdpTransformManager(sdp, {
        contentSlides: props.contentSlides,
        videoAS: props.bandwidth,
        packetizationMode: props.packetizationMode,
        vp9Disabled: props.vp9Disabled,
        allow1080p: props.allow1080p,
        allow4kPreso: props.allow4kPreso
      });
      const offer = sdpManager.getSdp();
      props.localFingerprints = sdpManager.getFingerprints();
      await calculateSecureCheckCode();
      log2.debug("SDP mangled", { sdp, offer });
      if (!eventHandlers.onIceCandidate) {
        await peer.setLocalDescription(offer);
        log2.info("setLocalDescription with offer success", {
          sdp,
          offer
        });
      } else {
        props.pendingOffer = offer;
      }
      return offer;
    },
    createAnswer: async (answerOptions) => {
      log2.info("call createAnswer", { param: answerOptions });
      const syncStreamTrack = async (stream, contentType) => {
        log2.info("Sync tracks with stream on answer", {
          stream,
          contentType
        });
        if (!stream) {
          return;
        }
        return await Promise.all(
          stream.getTracks().map((track) => {
            const kind = track.kind;
            transceivers[contentType][kind]?.sender.setStreams?.(
              stream
            );
            return transceivers[contentType][kind]?.sender.replaceTrack(track);
          })
        );
      };
      await syncStreamTrack(localMainStream, "main");
      await syncStreamTrack(localPresentationStream, "preso");
      const sdp = await peer.createAnswer({
        ...props.answerOptions,
        ...answerOptions
      });
      const sdpManager = new SdpTransformManager(sdp, {
        videoAS: props.bandwidth,
        contentSlides: props.contentSlides,
        allow4kPreso: props.allow4kPreso
      });
      if (!("setStreams" in RTCRtpSender.prototype)) {
        const associateStream = (stream, contentType) => {
          if (!stream) {
            return;
          }
          stream.getTracks().forEach((track) => {
            const kind = track.kind;
            const mid = transceivers[contentType][kind]?.mid;
            if (mid) {
              sdpManager.addMsidToMline(mid, stream.id);
            }
          });
        };
        associateStream(localMainStream, "main");
        associateStream(localPresentationStream, "preso");
      }
      const answer = sdpManager.getSdp();
      props.localFingerprints = sdpManager.getFingerprints();
      await calculateSecureCheckCode();
      log2.debug("SDP mangled", { sdp, answer });
      await peer.setLocalDescription(answer);
      log2.info("setLocalDescription with answer success", { sdp, answer });
      return answer;
    },
    receiveAnswer: async (answer) => {
      remoteStreams = [];
      remoteStreamlessTracks = [];
      props.negotiationInProgress = false;
      remoteMediaLines = getMediaLines(answer.sdp);
      log2.info("call receiveAnswer", { answer });
      if (eventHandlers.onIceCandidate && props.pendingOffer) {
        await peer.setLocalDescription(props.pendingOffer);
        props.pendingOffer = void 0;
      }
      props.remoteFingerprints = new SdpTransformManager(
        answer
      ).getFingerprints();
      await calculateSecureCheckCode();
      props.currentRemoteDescription = answer;
      return await peer.setRemoteDescription(
        new SdpTransformManager(answer, {
          videoAS: props.bandwidth,
          videoTIAS: props.bandwidth * 1e3,
          contentSlides: props.contentSlides
        }).getSdp()
      );
    },
    receiveOffer: async (offer) => {
      remoteStreams = [];
      remoteStreamlessTracks = [];
      remoteMediaLines = getMediaLines(offer.sdp);
      log2.info("call receiveOffer", { offer });
      props.currentRemoteDescription = offer;
      props.remoteFingerprints = new SdpTransformManager(
        offer
      ).getFingerprints();
      await calculateSecureCheckCode();
      await peer.setRemoteDescription(offer);
      const currentTransceivers = peer.getTransceivers();
      log2.info("receiveOffer after setRemoteDescription", {
        currentTransceivers,
        transceivers
      });
      if (currentTransceivers.find((trans) => trans.mid === null)) {
        currentTransceivers.filter((trans) => trans.mid).forEach((trans) => {
          const kind = trans.receiver.track.kind;
          const contentType = remoteMediaLines.find(
            (media) => isPreso(media) && String(media.mid) === trans.mid
          ) ? "preso" : "main";
          const currentTrans = transceivers[contentType][kind];
          if (currentTrans?.mid === null) {
            const direction = props.transceiversDirection?.[contentType]?.[kind];
            if (direction) {
              trans.direction = direction;
            }
            currentTrans.stop();
            transceivers[contentType][kind] = trans;
            log2.info("Sync transceiver", {
              kind,
              contentType,
              oldTransceiver: currentTrans,
              newTransceiver: trans
            });
          }
        });
      }
    },
    receiveIceCandidate: async (candidate) => {
      log2.info("call receiveIceCandidate", { candidate });
      return await peer.addIceCandidate(candidate);
    },
    restartIce,
    getConfiguration: () => peer.getConfiguration(),
    setConfiguration: typeof peer.setConfiguration === "function" ? (rtcConfig) => peer.setConfiguration(rtcConfig) : void 0,
    isRemoteMainTrack,
    isRemoteMainStream: (stream) => {
      const transceivers2 = peer.getTransceivers();
      const getIsRemoteMainTrack = isRemoteMainTrack(transceivers2);
      log2.info("isRemoteMainStream", {
        stream,
        tracks: stream.getTracks(),
        transceivers: transceivers2.map(({ mid, receiver }) => ({
          mid,
          receiverTrack: receiver.track
        })),
        remoteMediaLines
      });
      return stream.getTracks().some(getIsRemoteMainTrack);
    },
    close: () => {
      log2.info("call close");
      clearTimer("connectionState");
      clearTimer("iceConnectionState");
      peer.close();
      localMainStream = void 0;
      localPresentationStream = void 0;
      remoteStreams = [];
    },
    set onConnectionStateChange(handler) {
      peer.onconnectionstatechange = (event) => {
        clearTimer("connectionState", () => {
          log2.debug("clears the connection state timer", { event });
        });
        if (peer.connectionState === "disconnected") {
          log2.debug(
            "waits for emitting connection state change because it is disconnected",
            { event }
          );
          timers.connectionState = window.setTimeout(() => {
            log2.debug(
              "emits connection state change after waiting",
              { event }
            );
            handler?.(event);
          }, 5e3 /* ConnectionState */);
        } else {
          handler?.(event);
        }
      };
    },
    set onDataChannel(handler) {
      peer.ondatachannel = handler;
    },
    set onIceCandidate(handler) {
      eventHandlers.onIceCandidate = handler;
    },
    set onIceCandidateError(handler) {
      peer.onicecandidateerror = handler;
    },
    set onIceConnectionStateChange(handler) {
      eventHandlers.iceConnectionStateChange = handler;
    },
    set onIceGatheringStateChange(handler) {
      peer.onicegatheringstatechange = handler ?? null;
    },
    set onNegotiationNeeded(handler) {
      eventHandlers.negotiationNeeded = handler;
    },
    set onSignalingStateChange(handler) {
      eventHandlers.signalingStateChange = handler;
    },
    set onTransceiverChange(handler) {
      eventHandlers.onTransceiverChange = handler;
    },
    set onSecureCheckCode(handler) {
      eventHandlers.onSecureCheckCode = handler;
    },
    set onTrack(handler) {
      eventHandlers.onTrack = handler;
    },
    set onRemoteStreams(handler) {
      eventHandlers.onRemoteStreams = handler;
    }
  };
}
function createMainPeerConnection(signals, options = {}) {
  const peer = createPeerConnection({
    transceiversDirection: {
      main: {
        audio: "sendrecv",
        video: "sendrecv"
      }
    },
    ...options
  });
  peer.setReference("module", "MainPeerConnection");
  const { onOfferRequired, ...restSignals } = signals;
  const log2 = createRefsLog(createGetRefs(peer));
  let subscriptions = [
    ...withSignals(peer)(restSignals),
    onOfferRequired.add((stream) => {
      log2.info("handle onOfferRequired signal", {
        localStream: peer.localStream,
        newStream: stream
      });
      if (stream) {
        peer.setLocalStream(stream).catch((e) => {
          logger.error(e, "setLocalStream failed");
        });
      } else {
        peer.negotiationNeeded = true;
      }
    })
  ];
  const cleanup = () => {
    subscriptions = subscriptions.flatMap((unsubscribe) => {
      unsubscribe();
      return [];
    });
  };
  return {
    get connectionState() {
      return peer.connectionState;
    },
    get iceConnectionState() {
      return peer.iceConnectionState;
    },
    get iceGatheringState() {
      return peer.iceGatheringState;
    },
    get signalingState() {
      return peer.signalingState;
    },
    get localStream() {
      return peer.localStream;
    },
    get remoteStreams() {
      return peer.remoteStreams;
    },
    get senders() {
      return peer.senders;
    },
    get receivers() {
      return peer.receivers;
    },
    get hasICECandidates() {
      return peer.hasICECandidates;
    },
    get bandwidth() {
      return peer.bandwidth;
    },
    get references() {
      return peer.references;
    },
    set bandwidth(bandwidth) {
      peer.bandwidth = bandwidth;
    },
    get offerOptions() {
      return peer.offerOptions;
    },
    get answerOptions() {
      return peer.answerOptions;
    },
    set offerOptions(newOptions) {
      peer.offerOptions = newOptions;
    },
    set answerOptions(newOptions) {
      peer.answerOptions = newOptions;
    },
    setLocalStream: peer.setLocalStream,
    setReference(key, value) {
      peer.setReference(key, value);
    },
    getStats: (selector) => peer.getStats(selector),
    getTransceiver: (type, contentType) => peer.getTransceiver(type, contentType),
    createDataChannel: (label, dataChannelDict) => peer.createDataChannel(label, dataChannelDict),
    restartIce: peer.restartIce,
    getConfiguration: peer.getConfiguration,
    setConfiguration: peer.setConfiguration,
    isRemoteMainStream: peer.isRemoteMainStream,
    isRemoteMainTrack: peer.isRemoteMainTrack,
    close: () => {
      cleanup();
      peer.close();
    }
  };
}
export {
  REQUIRED_SIGNAL_KEYS,
  RecoveryTimeout,
  createGetRefs,
  createMainPeerConnection,
  createPCSignal,
  createPCSignals,
  createPeerConnection,
  createRefsLog,
  getCreateLoopbackConnectionFn,
  getPeerConnectionStates,
  getStatesAndProps,
  handleOnTrack,
  isSameStream,
  logReferences,
  logger,
  wirePeerConnectionEventHandler,
  wirePeerConnectionEvents,
  withSignals
};
