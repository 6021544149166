// src/types.ts
var MediaDeviceKinds = /* @__PURE__ */ ((MediaDeviceKinds2) => {
  MediaDeviceKinds2["AUDIOINPUT"] = "audioinput";
  MediaDeviceKinds2["AUDIOOUTPUT"] = "audiooutput";
  MediaDeviceKinds2["VIDEOINPUT"] = "videoinput";
  return MediaDeviceKinds2;
})(MediaDeviceKinds || {});
var FACING_MODE = ["user", "environment", "left", "right"];
var MediaDeviceFailure = /* @__PURE__ */ ((MediaDeviceFailure2) => {
  MediaDeviceFailure2["AbortError"] = "AbortError";
  MediaDeviceFailure2["AudioAndVideoDeviceNotFoundError"] = "AudioAndVideoDeviceNotFoundError";
  MediaDeviceFailure2["AudioInputDeviceNotFoundError"] = "AudioInputDeviceNotFoundError";
  MediaDeviceFailure2["MissingConstraintsError"] = "MissingConstraintsError";
  MediaDeviceFailure2["NotAllowedError"] = "NotAllowedError";
  MediaDeviceFailure2["NotFoundError"] = "NotFoundError";
  MediaDeviceFailure2["NotReadableError"] = "NotReadableError";
  MediaDeviceFailure2["OverconstrainedError"] = "OverconstrainedError";
  MediaDeviceFailure2["PermissionDeniedError"] = "PermissionDeniedError";
  MediaDeviceFailure2["SecurityError"] = "SecurityError";
  MediaDeviceFailure2["TrackStartError"] = "TrackStartError";
  MediaDeviceFailure2["TypeError"] = "TypeError";
  MediaDeviceFailure2["VideoInputDeviceNotFoundError"] = "VideoInputDeviceNotFoundError";
  MediaDeviceFailure2["NotSupportedError"] = "NotSupportedError";
  MediaDeviceFailure2["StreamTrackNotFound"] = "StreamTrackNotFound";
  return MediaDeviceFailure2;
})(MediaDeviceFailure || {});

// src/typeGuards.ts
import { hasOwnProperty } from "@pexip/utils";
var isBoolean = (t) => typeof t === "boolean";
var isUndefined = (t) => typeof t === "undefined";
var isNumber = (t) => typeof t === "number" && !Number.isNaN(t);
var isInteger = (t) => Number.isInteger(t);
var isFloat = (t) => isNumber(t) && !Number.isInteger(t) && Number.isFinite(t);
var CONSTRAIN_STRING_KEYS = [
  "facingMode",
  "resizeMode",
  "deviceId",
  "groupId"
];
var EXTENDED_CONSTRAIN_STRING_KEYS = [
  "videoSegmentation",
  "videoSegmentationModel",
  "bgImageUrl"
];
var CONSTRAIN_U_LONG_KEYS = [
  "width",
  "height",
  "sampleRate",
  "sampleSize",
  "channelCount"
];
var EXTENDED_CONSTRAIN_U_LONG_KEYS = [
  "backgroundBlurAmount",
  "edgeBlurAmount"
];
var CONSTRAIN_DOUBLE_KEYS = [
  "aspectRatio",
  "frameRate",
  "latency"
];
var EXTENDED_CONSTRAIN_DOUBLE_KEYS = ["foregroundThreshold"];
var CONSTRAIN_BOOLEAN_KEYS = [
  "echoCancellation",
  "autoGainControl",
  "noiseSuppression"
];
var EXTENDED_CONSTRAIN_BOOLEAN_KEYS = [
  "vad",
  "asd",
  "mixWithAdditionalMedia",
  "denoise",
  "flipHorizontal"
];
var CONSTRAINT_SET_KEYS = [
  ...CONSTRAIN_DOUBLE_KEYS,
  ...CONSTRAIN_U_LONG_KEYS,
  ...CONSTRAIN_STRING_KEYS,
  ...CONSTRAIN_BOOLEAN_KEYS
];
var isConstrainStringKeys = (t) => CONSTRAIN_STRING_KEYS.includes(t);
var isExtendedConstrainStringKeys = (t) => EXTENDED_CONSTRAIN_STRING_KEYS.includes(t);
var isConstrainULongKeys = (t) => CONSTRAIN_U_LONG_KEYS.includes(t);
var isExtendedConstrainULongKeys = (t) => EXTENDED_CONSTRAIN_U_LONG_KEYS.includes(t);
var isConstrainDoubleKeys = (t) => CONSTRAIN_DOUBLE_KEYS.includes(t);
var isExtendedConstrainDoubleKeys = (t) => EXTENDED_CONSTRAIN_DOUBLE_KEYS.includes(t);
var isConstrainBooleanKeys = (t) => CONSTRAIN_BOOLEAN_KEYS.includes(t);
var isExtendedConstrainBooleanKeys = (t) => EXTENDED_CONSTRAIN_BOOLEAN_KEYS.includes(t);
var isMediaTrackConstraintSetKey = (t) => CONSTRAINT_SET_KEYS.includes(t);
var isMediaTrackConstraintsKey = (t) => isMediaTrackConstraintSetKey(t) || t === "advanced";
var isMediaTrackConstraints = (t) => {
  if (!t || typeof t !== "object" || Array.isArray(t) || t === null) {
    return false;
  }
  const keys = Object.keys(t);
  return !!keys.length && Object.keys(t).every((key) => isMediaTrackConstraintsKey(key));
};
var isMediaDeviceInfo = (t) => {
  if (!t || typeof t !== "object") {
    return false;
  }
  return !!t && "deviceId" in t && "kind" in t;
};
var isMediaDeviceInfoArray = (t) => {
  if (Array.isArray(t) && t.length && t.some(isMediaDeviceInfo)) {
    return true;
  }
  return false;
};
var isDeviceConstraint = (t) => {
  return isMediaDeviceInfo(t) || isMediaDeviceInfoArray(t);
};
var isConstraintDOMString = (t) => typeof t === "string" && !!t || Array.isArray(t) && t.some(Boolean);
var CONSTRAIN_PARAM_KEYS = ["exact", "ideal"];
var CONSTRAIN_RANGE_KEYS = ["min", "max"];
var CONSTRAIN_KEYS = [
  ...CONSTRAIN_PARAM_KEYS,
  ...CONSTRAIN_RANGE_KEYS
];
var isConstrainDOMParameters = (isType, keys = CONSTRAIN_PARAM_KEYS) => (t) => {
  if (!t || typeof t !== "object" || t === null || Object.keys(t).length <= 0) {
    return false;
  }
  return keys.some((key) => hasOwnProperty(t, key) && isType(t[key]));
};
var isConstrainDOMStringParameters = isConstrainDOMParameters(
  isConstraintDOMString
);
var isConstrainBooleanParameters = isConstrainDOMParameters(isBoolean);
var isConstrainRange = isConstrainDOMParameters(
  (t) => isFloat(t) || isInteger(t),
  CONSTRAIN_RANGE_KEYS
);
var isConstrainDoubleRange = isConstrainDOMParameters(isFloat, CONSTRAIN_KEYS);
var isConstrainULongRange = isConstrainDOMParameters(isInteger, CONSTRAIN_KEYS);
var isConstraintDeviceParameters = isConstrainDOMParameters(
  (t) => isMediaDeviceInfo(t) || isMediaDeviceInfoArray(t)
);
var isConstraintSetDevice = (t) => isMediaDeviceInfo(t) || isMediaDeviceInfoArray(t) || isConstraintDeviceParameters(t);
var isExtendedConstraint = (t) => {
  if (typeof t !== "object" || t === null) {
    return false;
  }
  if (hasOwnProperty(t, "device")) {
    const { device } = t;
    return isConstraintSetDevice(device);
  }
  return [
    ...EXTENDED_CONSTRAIN_DOUBLE_KEYS,
    ...EXTENDED_CONSTRAIN_STRING_KEYS,
    ...EXTENDED_CONSTRAIN_U_LONG_KEYS,
    ...EXTENDED_CONSTRAIN_BOOLEAN_KEYS
  ].some((key) => hasOwnProperty(t, key));
};
var isInputConstraintSet = (t) => {
  if (typeof t !== "object" || t === null) {
    return false;
  }
  return isExtendedConstraint(t) || isMediaTrackConstraints(t);
};
var isMediaStreamTrack = (m) => {
  if (m && typeof m === "object") {
    return !!m && "getSettings" in m;
  }
  return false;
};
var isFacingMode = (s) => {
  if (typeof s === "string" && FACING_MODE.includes(s)) {
    return true;
  }
  return false;
};

// src/logger.ts
var noopLogger = Object.freeze({
  trace() {
  },
  debug() {
  },
  info() {
  },
  warn() {
  },
  error() {
  }
});
var logger = noopLogger;
function setLogger(newLogger) {
  const oldLogger = logger;
  logger = newLogger;
  return oldLogger;
}

// src/utils.ts
var compareDevices = (deviceInfo, key = "deviceId") => (anotherDeviceInfo) => deviceInfo.kind === anotherDeviceInfo.kind && deviceInfo[key] === anotherDeviceInfo[key];
var findDevice = (deviceToFind, useFallback = true) => (devices) => {
  const compareIDTo = compareDevices(deviceToFind);
  const found = devices.find(
    (device) => compareIDTo(device)
  );
  if (found || !useFallback) {
    return found;
  }
  const compareLabelTo = compareDevices(deviceToFind, "label");
  return devices.find(
    (device) => compareLabelTo(device)
  );
};

// src/constraints.ts
var isExactDeviceConstraint = (constraint) => {
  if (isInputConstraintSet(constraint)) {
    return !!constraint.device && typeof constraint.device === "object" && "exact" in constraint.device || !!constraint.deviceId && typeof constraint.deviceId === "object" && "exact" in constraint.deviceId;
  }
  return false;
};
var getConstraintsSetFilter = (supportedConstraints) => (trackConstraints) => {
  if (!isMediaTrackConstraints(trackConstraints)) {
    return trackConstraints;
  }
  const constraints = Object.entries(trackConstraints).reduce(
    (acc, [key, val]) => key === "advanced" || supportedConstraints.has(
      key
    ) ? { ...acc, [key]: val } : acc,
    {}
  );
  if (Object.keys(constraints).length === 0) {
    return true;
  }
  return constraints;
};
var getDefinedOnly = (obj) => {
  return Object.entries(obj).reduce(
    (acc, [key, val]) => typeof val === "undefined" ? acc : { ...acc, [key]: val },
    {}
  );
};
var removeUnsupportedConstraints = (constraints) => {
  if (!(navigator && "getSupportedConstraints" in navigator.mediaDevices)) {
    return constraints;
  }
  const supportedConstraints = Object.entries(
    navigator.mediaDevices.getSupportedConstraints()
  );
  logger.debug({ supportedConstraints }, "Supported constraints");
  const constraintsSetFilter = getConstraintsSetFilter(
    supportedConstraints.reduce(
      (set, [key, value]) => value && isMediaTrackConstraintSetKey(key) ? set.add(
        key
      ) : set,
      /* @__PURE__ */ new Set()
    )
  );
  return getDefinedOnly({
    audio: constraintsSetFilter(constraints.audio),
    peerIdentity: constraints.peerIdentity,
    video: constraintsSetFilter(constraints.video)
  });
};
var mergeConstraints = (baseConstraints) => (constraints) => {
  if (!isInputConstraintSet(baseConstraints)) {
    if (isDeviceConstraint(constraints)) {
      return { device: constraints };
    }
    return constraints ?? false;
  }
  if (!constraints) {
    return false;
  }
  if (isDeviceConstraint(constraints)) {
    const result = {
      ...baseConstraints,
      device: constraints
    };
    delete result.facingMode;
    delete result.deviceId;
    return result;
  }
  if (isInputConstraintSet(constraints)) {
    const combined = { ...baseConstraints, ...constraints };
    const hasDeviceId = isConstraintDOMString(combined.deviceId) || isConstrainDOMStringParameters(combined.deviceId);
    const hasDevice = isDeviceConstraint(combined.device) || isConstraintDeviceParameters(combined.device);
    if (hasDeviceId && hasDevice) {
      const result = {
        ...combined
      };
      delete result.facingMode;
      delete result.deviceId;
      return result;
    }
    if (hasDevice || hasDeviceId) {
      delete combined.facingMode;
      return combined;
    }
    return combined;
  }
  return baseConstraints;
};
var normalizeDevice = (device) => {
  if (!device) {
    return void 0;
  }
  if (isMediaDeviceInfo(device)) {
    return [device];
  }
  if (Array.isArray(device)) {
    const devices = device.filter(isMediaDeviceInfo);
    if (devices.length) {
      return devices;
    }
  }
  return void 0;
};
var normalizeDeviceConstraint = (constraints) => {
  if (!constraints) {
    return void 0;
  }
  if (isConstraintDeviceParameters(constraints)) {
    return Object.keys(constraints).filter((k) => ["ideal", "exact"].includes(k)).reduce((cs, k) => {
      const key = k;
      const value = constraints[key];
      const devices = normalizeDevice(value);
      if (devices) {
        return { ...cs ?? {}, [key]: devices };
      }
      return cs;
    }, void 0);
  }
  const normalized = normalizeDevice(constraints);
  return normalized && { ideal: normalized };
};
var toDeviceIdConstraintSet = (constraint) => {
  const [devices, param] = extractConstrainDevice({ device: constraint });
  return devices && { [param]: devices.map((device) => device.deviceId) };
};
var toArray = (t) => {
  const r = Array.isArray(t) ? t : [t];
  return r.filter(Boolean);
};
var NONE = [void 0, "ideal"];
var extractConstrainString = (key) => (constraints) => {
  const constraint = constraints[key];
  if (!constraint) {
    return NONE;
  }
  if (isConstraintDOMString(constraint)) {
    return [toArray(constraint), "ideal"];
  }
  if (isConstrainDOMStringParameters(constraint)) {
    if (constraint.exact) {
      const normalized = toArray(constraint.exact);
      if (normalized.length) {
        return [normalized, "exact"];
      }
    }
    if (constraint.ideal) {
      return [toArray(constraint.ideal), "ideal"];
    }
  }
  return NONE;
};
var extractConstrainBoolean = (key) => (constraints) => {
  const constraint = constraints[key];
  if (isUndefined(constraint)) {
    return NONE;
  }
  if (isBoolean(constraint)) {
    return [constraint, "ideal"];
  }
  if (isConstrainBooleanParameters(constraint)) {
    const { ideal, exact } = constraint;
    if (isBoolean(exact)) {
      return [exact, "exact"];
    }
    if (isBoolean(ideal)) {
      return [ideal, "ideal"];
    }
  }
  return NONE;
};
var extractConstrainNumber = (key) => (constraints) => {
  const constraint = constraints[key];
  if (constraint === void 0 || constraint === null) {
    return NONE;
  }
  if (isFloat(constraint) || isInteger(constraint)) {
    return [constraint, "ideal"];
  }
  if (isConstrainULongRange(constraint) || isConstrainDoubleRange(constraint)) {
    const { exact, ideal, min, max } = constraint;
    if (isFloat(min) || isFloat(max) || isInteger(min) || isInteger(max)) {
      return [{ min, max, ideal, exact }, "min-max"];
    }
    if (isFloat(exact) || isInteger(exact)) {
      return [exact, "exact"];
    }
    if (isFloat(ideal) || isInteger(ideal)) {
      return [ideal, "ideal"];
    }
  }
  return NONE;
};
var extractConstrainDevice = (constraints) => {
  if (!constraints || isBoolean(constraints) || Array.isArray(constraints) && !constraints.length) {
    return NONE;
  }
  const { device } = isInputConstraintSet(constraints) ? constraints : { device: constraints };
  if (isMediaDeviceInfo(device) || isMediaDeviceInfoArray(device)) {
    const normalized = normalizeDevice(device);
    if (normalized) {
      return [normalized, "ideal"];
    }
  }
  if (isConstraintDeviceParameters(device)) {
    const { exact, ideal } = normalizeDeviceConstraint(device) ?? {};
    if (exact) {
      return [exact, "exact"];
    }
    if (ideal) {
      return [ideal, "ideal"];
    }
  }
  return NONE;
};
var closedTo = (a, b, numDigits = 5) => {
  const multiplier = Math.pow(10, numDigits);
  return Math.round(a * multiplier) === Math.round(b * multiplier);
};
var between = (min, num, max) => {
  if (!isUndefined(min)) {
    if (!isUndefined(max)) {
      return num >= min && num <= max;
    }
    return num >= min;
  }
  if (!isUndefined(max)) {
    return num <= max;
  }
  return true;
};
var getValueFromConstrainNumber = (constraint) => {
  if (isNumber(constraint)) {
    return constraint;
  }
  const { min, max, ideal, exact } = constraint;
  if (exact !== void 0) {
    const withinRange = between(min, exact, max);
    if (withinRange) {
      return exact;
    }
  }
  if (ideal !== void 0) {
    const withinRange = between(min, ideal, max);
    if (withinRange) {
      return ideal;
    }
  }
  const value = max ?? min;
  if (value !== void 0) {
    return value;
  }
  throw Error("Constrain Number is undefined");
};
var getFacingModeFromConstraintString = (constraint) => {
  if (isFacingMode(constraint)) {
    return constraint;
  }
  return void 0;
};
var satisfyConstrainNumber = (constraint, num) => {
  if (isUndefined(constraint) || isUndefined(num)) {
    return true;
  }
  if (isInteger(constraint)) {
    return num === constraint;
  }
  if (isFloat(constraint)) {
    return closedTo(constraint, num);
  }
  if (isConstrainRange(constraint)) {
    const { min, max, ideal, exact } = constraint;
    const withinRange = between(min, num, max);
    if (isFloat(exact)) {
      return withinRange && closedTo(exact, num);
    }
    if (isInteger(exact)) {
      return withinRange && exact === num;
    }
    if (isFloat(ideal)) {
      return withinRange && closedTo(ideal, num);
    }
    if (isInteger(ideal)) {
      return withinRange && ideal === num;
    }
    return withinRange;
  }
  return false;
};
var resolveMediaDeviceConstraints = (constraints, base) => {
  const merge = mergeConstraints(base);
  if (isInputConstraintSet(constraints)) {
    if (isConstraintSetDevice(constraints.device)) {
      return merge(
        Object.keys(constraints).reduce((cs, k) => {
          const key = k;
          if (key === "device") {
            return {
              ...cs,
              deviceId: toDeviceIdConstraintSet(
                constraints.device
              )
            };
          }
          return { ...cs, [key]: constraints[key] };
        }, {})
      );
    }
    return merge(constraints);
  }
  if (isConstraintSetDevice(constraints)) {
    const deviceId = toDeviceIdConstraintSet(constraints);
    return merge({ deviceId });
  }
  if (constraints && isMediaTrackConstraints(base)) {
    return base;
  }
  return !!constraints;
};
var getMediaConstraints = ({
  audio,
  video,
  defaultConstraints
}) => {
  return removeUnsupportedConstraints({
    audio: resolveMediaDeviceConstraints(audio, defaultConstraints.audio),
    video: resolveMediaDeviceConstraints(video, defaultConstraints.video)
  });
};
var applyConstraints = async (tracks, constraints) => {
  if (!tracks || tracks.length === 0 || Object.keys(constraints).length === 0) {
    return Promise.resolve();
  }
  const { audio, video } = removeUnsupportedConstraints({
    audio: resolveMediaDeviceConstraints(constraints.audio),
    video: resolveMediaDeviceConstraints(constraints.video)
  });
  await Promise.all(
    tracks.flatMap((track) => {
      if (track.kind === "audio" && isMediaTrackConstraints(audio)) {
        return [track.applyConstraints(audio)];
      }
      if (track.kind === "video" && isMediaTrackConstraints(video)) {
        return [track.applyConstraints(video)];
      }
      return [];
    })
  );
};
var findDeviceFrom = (devicesToFind, deviceList) => {
  const devicesFound = devicesToFind.flatMap((device) => {
    const found = findDevice(device)(deviceList);
    if (found) {
      return [found];
    }
    return [];
  });
  if (devicesFound.length) {
    return devicesFound;
  }
  return void 0;
};
var findDeviceFromDeviceConstraints = (device, devices) => {
  const normalized = normalizeDeviceConstraint(device);
  if (normalized) {
    const { ideal, exact } = normalized;
    if (exact) {
      return findDeviceFrom(exact, devices);
    }
    if (ideal) {
      return findDeviceFrom(ideal, devices);
    }
  }
};
var relaxDevice = (device, devices) => {
  const found = findDeviceFromDeviceConstraints(device, devices);
  if (found) {
    return found;
  }
  return normalizeDevice(device);
};
var resolveOnlyDevice = (devices) => {
  switch (devices.length) {
    case 1:
      return devices[0];
    case 0:
      return void 0;
    default:
      return true;
  }
};
function extractConstraints(key) {
  return (constraints) => {
    if (!constraints || typeof constraints === "boolean" || Array.isArray(constraints) && !constraints.length) {
      return NONE;
    }
    if (key === "device" && (isMediaDeviceInfo(constraints) || isMediaDeviceInfoArray(constraints))) {
      return extractConstrainDevice(constraints);
    }
    if (isInputConstraintSet(constraints)) {
      if (key === "device") {
        return extractConstrainDevice(constraints);
      }
      if (isConstrainStringKeys(key) || isExtendedConstrainStringKeys(key)) {
        return extractConstrainString(key)(constraints);
      }
      if (isConstrainDoubleKeys(key) || isConstrainULongKeys(key) || isExtendedConstrainULongKeys(key) || isExtendedConstrainDoubleKeys(key)) {
        return extractConstrainNumber(key)(constraints);
      }
      if (isConstrainBooleanKeys(key) || isExtendedConstrainBooleanKeys(key)) {
        return extractConstrainBoolean(key)(constraints);
      }
    }
    return [void 0, "ideal"];
  };
}
var extractConstraintsWithKeys = (keys) => (constraints) => keys.reduce((result, key) => {
  if (key === "device") {
    return { ...result, [key]: extractConstraints(key)(constraints) };
  }
  if (isConstrainULongKeys(key) || isConstrainDoubleKeys(key) || isExtendedConstrainULongKeys(key) || isExtendedConstrainDoubleKeys(key)) {
    return { ...result, [key]: extractConstraints(key)(constraints) };
  }
  if (isConstrainStringKeys(key) || isExtendedConstrainStringKeys(key)) {
    return { ...result, [key]: extractConstraints(key)(constraints) };
  }
  if (isConstrainBooleanKeys(key) || isExtendedConstrainBooleanKeys(key)) {
    return { ...result, [key]: extractConstraints(key)(constraints) };
  }
  return result;
}, {});
var extractDeviceId = extractConstrainString("deviceId");
var findDeviceFromConstraints = (constraints, devices) => {
  if (typeof constraints === "boolean") {
    return constraints ? resolveOnlyDevice(devices) : constraints;
  }
  if (constraints === void 0 || !devices.length || devices.some((device) => !device.label) || Array.isArray(constraints) && !constraints.length) {
    return void 0;
  }
  const [constrainDevices, deviceParam] = extractConstraints("device")(constraints);
  if (constrainDevices) {
    const [device] = findDeviceFrom(constrainDevices, devices) ?? [];
    if (device) {
      return device;
    }
    if (deviceParam === "exact") {
      return void 0;
    }
  }
  const [ConstrainDeviceIds, deviceIdParam] = extractConstraints("deviceId")(constraints);
  if (ConstrainDeviceIds) {
    const deviceFound = devices.find((device) => {
      const id = ConstrainDeviceIds.find(
        (id2) => id2 && device.label && device.deviceId === id2
      );
      return !!id;
    });
    if (deviceFound) {
      return deviceFound;
    }
    if (deviceIdParam === "exact") {
      return void 0;
    }
  }
  return resolveOnlyDevice(devices);
};
var relaxInputConstraint = (input, devices) => {
  if (devices.length === 0 || !input || typeof input === "boolean") {
    return input;
  }
  const [device, param] = extractConstrainDevice(input);
  const relaxedDevice = relaxDevice(device, devices);
  const otherConstraints = isInputConstraintSet(input) ? input : {};
  return relaxedDevice ? { ...otherConstraints, device: { [param]: relaxedDevice } } : input;
};
var getConstraintsHandlers = () => {
  let defaultConstraints = {
    audio: {
      echoCancellation: { ideal: true },
      noiseSuppression: { ideal: true }
    },
    video: {
      aspectRatio: { ideal: 1.7777777778 },
      facingMode: { ideal: "user" },
      frameRate: { ideal: 30 },
      height: { ideal: 720, max: 1080 },
      width: { ideal: 1280, max: 1920 }
    }
  };
  const setDefaultConstraints2 = (newConstraints) => {
    defaultConstraints = { ...defaultConstraints, ...newConstraints };
  };
  const getDefaultConstraints = () => {
    return defaultConstraints;
  };
  return {
    getDefaultConstraints,
    setDefaultConstraints: setDefaultConstraints2
  };
};

// src/devices.ts
var SEPARATOR = ":";
var toKey = ({ id, kind, label }) => [kind, id, label].join(SEPARATOR);
var getDevices = async () => {
  if ("mediaDevices" in navigator && "enumerateDevices" in navigator.mediaDevices) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices;
  }
  return [];
};
var toDeviceKey = (device) => toKey({ id: device.deviceId, kind: device.kind, label: device.label });
var toDeviceTuple = (device) => [toDeviceKey(device), device];
var toDevicesMap = (devices) => {
  return new Map(devices.map(toDeviceTuple));
};
var toUniqueDevices = (devices) => {
  const map = toDevicesMap(devices);
  return Array.from(map.values());
};
var createTrackDevicesChanges = (prevDevices = []) => {
  let seen = toDevicesMap(prevDevices);
  return (devices) => {
    const found = [];
    const lost = [];
    const uniqueDevices = toUniqueDevices(devices);
    const { authorized, unauthorized } = uniqueDevices.reduce(
      (ds, d) => {
        if (d.label) {
          ds.authorized.push(d);
        } else {
          ds.unauthorized.push(d);
        }
        return ds;
      },
      { authorized: [], unauthorized: [] }
    );
    for (const device of authorized) {
      if (!seen.delete(toDeviceKey(device))) {
        found.push(device);
      }
    }
    for (const device of seen.values()) {
      lost.push(device);
    }
    seen = new Map(authorized.map(toDeviceTuple));
    return { unauthorized, authorized, found, lost, devices: uniqueDevices };
  };
};
var deviceChanged = (fn) => {
  const trackChanges = createTrackDevicesChanges();
  const odc = async () => {
    const devices = await getDevices();
    const changes = trackChanges(devices);
    fn(changes);
  };
  if (navigator.mediaDevices && "ondevicechange" in navigator.mediaDevices) {
    navigator.mediaDevices.ondevicechange = odc;
    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }
  const it = window.setInterval(() => void odc(), 5e3);
  return () => {
    window.clearInterval(it);
  };
};
var extractDeviceInfo = (track) => {
  const { deviceId, groupId, ...settings } = track.getSettings();
  const kind = track.kind === "audio" ? "audioinput" /* AUDIOINPUT */ : "videoinput" /* VIDEOINPUT */;
  return {
    kind,
    deviceId: deviceId ?? "",
    groupId: groupId ?? "",
    label: track.label,
    settings
  };
};
var toMediaDeviceInfoLike = (track) => {
  const { deviceId, label, kind, groupId } = extractDeviceInfo(track);
  if (!deviceId) {
    return;
  }
  return { deviceId, label, kind, groupId };
};
var compareMediaDeviceToMediaTrack = (track) => (device) => {
  const { deviceId } = track.getSettings();
  const sameLabel = device.label === track.label;
  const sameKind = device.kind.includes(track.kind);
  if (deviceId) {
    return sameKind && deviceId === device.deviceId;
  }
  return sameKind && sameLabel;
};
var toMediaDeviceInfoLikeJSON = (info) => ({
  deviceId: info.deviceId,
  groupId: info.groupId,
  kind: info.kind,
  label: info.label,
  settings: info.settings
});
var findMediaInputFromMediaStreamTrack = (devices) => (track) => {
  if (!devices.length || !track) {
    return void 0;
  }
  const settings = track.getSettings();
  const onlyInput = devices.filter((d) => d.kind === `${track.kind}input`);
  const onlyDevice = onlyInput[0];
  if (onlyInput.length === 1 && onlyDevice) {
    onlyDevice.settings = settings;
    onlyDevice.toJSON = () => toMediaDeviceInfoLikeJSON(onlyDevice);
    return onlyDevice;
  }
  const device = devices.filter(
    (device2) => ["audioinput", "videoinput"].includes(device2.kind)
  ).find(compareMediaDeviceToMediaTrack(track));
  if (device) {
    device.settings = settings;
    device.toJSON = () => toMediaDeviceInfoLikeJSON(device);
    return device;
  }
  return device;
};
var findMediaInputFromStream = (devices) => (stream) => {
  if (!stream || !devices.length) {
    return {};
  }
  const findMediaInput = findMediaInputFromMediaStreamTrack(devices);
  return {
    audioInput: findMediaInput(...stream.getAudioTracks()),
    videoInput: findMediaInput(...stream.getVideoTracks())
  };
};
var compareDeviceConstraintAndTrackDevice = (device) => (constraint) => {
  if (!device || !constraint) {
    return Boolean(device) === Boolean(constraint);
  }
  const normalized = normalizeDeviceConstraint(constraint);
  if (normalized) {
    const requestingDevices = Object.values(
      normalized
    ).flatMap((t) => Array.isArray(t) ? t : [t]);
    const compare = compareDevices(
      device,
      device.label ? "label" : "deviceId"
    );
    return requestingDevices.some(compare);
  }
  return false;
};
var isRequestedResolution = (request, response) => {
  if (!response || !request) {
    return Boolean(response) === Boolean(request);
  }
  if (isInputConstraintSet(request)) {
    const [width] = extractConstrainNumber("width")(request);
    const [height] = extractConstrainNumber("height")(request);
    const sameWidth = satisfyConstrainNumber(
      width,
      response.settings?.width
    );
    const sameHeight = satisfyConstrainNumber(
      height,
      response.settings?.height
    );
    return sameWidth && sameHeight;
  }
  return true;
};
var isRequestedInputDevice = (request, response) => {
  if (!response || !request) {
    return Boolean(response) === Boolean(request);
  }
  const compareDevice = compareDeviceConstraintAndTrackDevice(response);
  if (isInputConstraintSet(request)) {
    if (isConstraintSetDevice(request.device)) {
      const isSameDevice = compareDevice(request.device);
      return isSameDevice;
    }
    if (request.deviceId) {
      const { deviceId } = response;
      if (!deviceId) {
        return true;
      }
      if (isConstraintDOMString(request.deviceId)) {
        const ids = toArray(request.deviceId);
        return ids.some((id) => deviceId === id);
      }
      if (isConstrainDOMStringParameters(request.deviceId)) {
        return Object.values(request.deviceId).flatMap(toArray).some((id) => id === deviceId);
      }
    }
    const facingMode = response.settings?.facingMode;
    if (request.facingMode && facingMode) {
      const [facingModes] = extractConstrainString("facingMode")(request);
      return facingModes?.some((fm) => fm === facingMode) ?? true;
    }
    return true;
  }
  if (isConstraintSetDevice(request)) {
    return compareDevice(request);
  }
  return !!response;
};
var isRequestedInputTrack = (request, current) => {
  if (!request || !current) {
    return Boolean(request) === Boolean(current);
  }
  if (isMediaDeviceInfo(current)) {
    return isRequestedInputDevice(request, current);
  }
  if (isMediaStreamTrack(current)) {
    const device = extractDeviceInfo(current);
    return isRequestedInputDevice(request, device);
  }
  return true;
};
var extractDevice = extractConstraintsWithKeys(["device", "deviceId"]);
var hasRequestingDevice = (request, kind, currentDevices) => {
  if (!request) {
    return false;
  }
  const {
    device: [devices],
    deviceId: [deviceIds]
  } = extractDevice(request);
  if ((!devices || devices.length === 0) && (!deviceIds || deviceIds.length === 0)) {
    return currentDevices.some((device) => device.kind === kind);
  }
  const find = (device) => findDevice(device)(currentDevices);
  return devices?.some((device) => find(device)) ?? deviceIds?.some(
    (deviceId) => find({ kind, deviceId, label: "", groupId: "" })
  ) ?? false;
};
var shouldRequestDevice = (request, tracks, currentDevices) => {
  if (!request || !currentDevices.some((device) => device.label)) {
    return false;
  }
  const { kind } = currentDevices[0] ?? {};
  if (!kind || currentDevices.some((device) => device.kind !== kind)) {
    throw new Error("Expect a single kind of device");
  }
  const [track] = tracks;
  if (tracks.length === 0 || tracks.some((track2) => track2.readyState === "ended")) {
    return true;
  }
  if (isRequestedInputTrack(request, track)) {
    return false;
  }
  if (hasRequestingDevice(request, kind, currentDevices)) {
    return true;
  }
  return false;
};
var resolveInputDevice = (tracksOrDevice, findInput) => {
  if (isMediaDeviceInfo(tracksOrDevice)) {
    return tracksOrDevice;
  }
  if (Array.isArray(tracksOrDevice)) {
    const [track] = tracksOrDevice;
    if (track?.readyState === "live") {
      return findInput(track) ?? track;
    }
  }
  return void 0;
};
var isStreamingRequestedDevicesBase = (request, tracksOrDevices, devices) => {
  const audioRequest = relaxInputConstraint(request.audio, devices);
  const videoRequest = relaxInputConstraint(request.video, devices);
  const findInput = findMediaInputFromMediaStreamTrack(devices);
  const audioInput = resolveInputDevice(tracksOrDevices.audio, findInput);
  const videoInput = resolveInputDevice(tracksOrDevices.video, findInput);
  const audio = isRequestedInputTrack(audioRequest, audioInput);
  const video = isRequestedInputTrack(videoRequest, videoInput);
  return { audio, video };
};
var isStreamingRequestedDevices = (request, stream, devices) => isStreamingRequestedDevicesBase(
  request,
  {
    audio: stream?.getAudioTracks(),
    video: stream?.getVideoTracks()
  },
  devices
);
var findPermissionGrantedDevices = (devices) => devices.filter((d) => d.label !== "");
var toPermissionState = (devices, anyActiveStream = false) => {
  const granted = devices.some((device) => device.label);
  if (anyActiveStream) {
    return granted ? "granted" : "denied";
  }
  return granted ? "granted" : "prompt";
};
var getInputDevicePermissionState = async (anyActiveStream = false) => {
  try {
    const video = await navigator.permissions.query({ name: "camera" });
    const audio = await navigator.permissions.query({ name: "microphone" });
    return { video: video.state, audio: audio.state };
  } catch {
    const devices = await getDevices();
    return ["videoinput", "audioinput"].reduce(
      (permission, kind) => {
        const state2 = toPermissionState(
          devices.filter((device) => device.kind === kind),
          anyActiveStream
        );
        permission[kind === "audioinput" ? "audio" : "video"] = state2;
        return permission;
      },
      { video: "prompt", audio: "prompt" }
    );
  }
};
var findCurrentAudioOutputId = (audioOutput, devices) => {
  let selectedAudioOutputDeviceId = "";
  if (audioOutput?.deviceId && devices) {
    const latestSelectedAudioInput = findDevice(audioOutput)(devices);
    if (latestSelectedAudioInput) {
      selectedAudioOutputDeviceId = latestSelectedAudioInput.deviceId;
    }
  }
  return selectedAudioOutputDeviceId;
};
var findCurrentVideoInputDeviceIdFromStream = (stream) => {
  const [videoTrack] = stream.getVideoTracks();
  return videoTrack && toMediaDeviceInfoLike(videoTrack)?.deviceId;
};
var findDeviceWithDeviceId = (devices, deviceId) => devices.find((d) => d.deviceId === deviceId);
var findDevicesByKind = (devices, kind) => devices.filter((d) => d.kind === kind);
var findAudioInputDevices = (devices) => findDevicesByKind(devices, "audioinput" /* AUDIOINPUT */);
var findVideoInputDevices = (devices) => findDevicesByKind(devices, "videoinput" /* VIDEOINPUT */);
var findAudioOutputDevices = (devices) => findDevicesByKind(devices, "audiooutput" /* AUDIOOUTPUT */);
var muteStreamTrack = (stream) => (mute, mediaType = "all") => {
  switch (mediaType) {
    case "audio":
      stream?.getAudioTracks().forEach((track) => {
        track.enabled = !mute;
      });
      break;
    case "video":
      stream?.getVideoTracks().forEach((track) => {
        track.enabled = !mute;
      });
      break;
    default:
      stream?.getTracks().forEach((track) => {
        track.enabled = !mute;
      });
      break;
  }
};
var stopMediaStream = (stream, onStopped) => {
  if (!stream) {
    return;
  }
  stream.getTracks().forEach((track) => {
    track.stop();
    onStopped?.(track);
  });
};
var areTracksEnabled = (stream, type) => {
  if (!stream) {
    return false;
  }
  const tracks = type === "audio" ? stream.getAudioTracks() : stream.getVideoTracks();
  return tracks.length > 0 && tracks.every((track) => track.enabled);
};
var hasAudioOrVideoInputs = (devices) => devices.some((d) => d.kind !== "audiooutput" /* AUDIOOUTPUT */);
var hasAudioInputs = (devices) => devices.some(({ kind }) => kind === "audioinput" /* AUDIOINPUT */);
var hasVideoInputs = (devices) => devices.some(({ kind }) => kind === "videoinput" /* VIDEOINPUT */);
var hasChangedInput = (oldInput, newInput) => {
  if (isMediaDeviceInfo(newInput)) {
    if (isMediaDeviceInfo(oldInput) && !compareDevices(oldInput)(newInput) || !oldInput) {
      return true;
    }
  } else if (oldInput) {
    return true;
  }
  return false;
};

// src/eventEmitter.ts
var MediaEventType = /* @__PURE__ */ ((MediaEventType2) => {
  MediaEventType2["Mute"] = "mute";
  MediaEventType2["Unmute"] = "unmute";
  MediaEventType2["Ended"] = "ended";
  MediaEventType2["DevicesChanged"] = "devices:changed";
  MediaEventType2["DevicesFound"] = "devices:found";
  MediaEventType2["DevicesLost"] = "devices:lost";
  MediaEventType2["DeviceLost"] = "device:lost";
  MediaEventType2["DevicesUnauthorized"] = "devices:unauthorized";
  MediaEventType2["NoInputDevices"] = "devices:noinput";
  MediaEventType2["Error"] = "error";
  MediaEventType2["Stream"] = "stream";
  return MediaEventType2;
})(MediaEventType || {});
var eventEmitter = () => {
  const element = document.createElement("a");
  const createEvent = (data) => {
    return new CustomEvent("data", { detail: data });
  };
  return {
    dispatch(event) {
      element.dispatchEvent(createEvent(event));
    },
    subscriber(listener, onUnsubscribe) {
      element.addEventListener(
        "data",
        listener,
        false
      );
      return () => {
        element.removeEventListener(
          "data",
          listener,
          false
        );
        onUnsubscribe();
      };
    }
  };
};

// src/errors.ts
var isDeviceInUseError = (error) => {
  return error === "NotReadableError" /* NotReadableError */ || error === "TrackStartError" /* TrackStartError */;
};
var isPermissionDeniedError = (error) => {
  return error === "NotAllowedError" /* NotAllowedError */ || error === "PermissionDeniedError" /* PermissionDeniedError */;
};
var isInputNotFoundError = ({ input, kind, devices }) => (error) => {
  if (error === "NotFoundError" /* NotFoundError */) {
    const hasDevices = devices.some((d) => d.kind === kind);
    if (typeof input === "boolean") {
      if (!hasDevices && input) {
        return true;
      }
      return input && !hasDevices;
    }
    if (isMediaTrackConstraints(input)) {
      const [deviceIds, requirement] = extractDeviceId(input);
      if (requirement === "ideal") {
        return !hasDevices;
      }
      if (requirement === "exact") {
        return !devices.some(
          (device) => deviceIds?.some(
            (id) => device.kind === kind && id === device.deviceId
          )
        );
      }
    }
  }
  return false;
};
var normalizeError = (errors, parameters = []) => {
  const error = errors.find((e) => e.fn(...parameters));
  if (error) {
    return error.type;
  }
  return false;
};
var normalizeGetUserMediaError = (browserError, constraints, devices) => {
  const isAudioInputNotFoundError = isInputNotFoundError({
    input: constraints.audio,
    kind: "audioinput",
    devices
  });
  const isVideoInputNotFoundError = isInputNotFoundError({
    input: constraints.video,
    kind: "videoinput",
    devices
  });
  const areBothInputsNotFoundError = (error2) => isAudioInputNotFoundError(error2) && isVideoInputNotFoundError(error2);
  const errorsFn = [
    {
      fn: isDeviceInUseError,
      type: "NotReadableError" /* NotReadableError */
    },
    {
      fn: isPermissionDeniedError,
      type: "NotAllowedError" /* NotAllowedError */
    },
    {
      fn: areBothInputsNotFoundError,
      type: "AudioAndVideoDeviceNotFoundError" /* AudioAndVideoDeviceNotFoundError */
    },
    {
      fn: isAudioInputNotFoundError,
      type: "AudioInputDeviceNotFoundError" /* AudioInputDeviceNotFoundError */
    },
    {
      fn: isVideoInputNotFoundError,
      type: "VideoInputDeviceNotFoundError" /* VideoInputDeviceNotFoundError */
    }
  ];
  const errorMsg = browserError.name === "Error" ? browserError.message : browserError.name;
  const error = normalizeError(errorsFn, [errorMsg]);
  return error ? error : errorMsg;
};

// src/getMediaStream.ts
var getMediaStream = async (request) => {
  if (!request.audio && !request.video) {
    throw new Error("MissingConstraintsError" /* MissingConstraintsError */);
  }
  const devices = await getDevices();
  const audio = relaxInputConstraint(request.audio, devices);
  const video = relaxInputConstraint(request.video, devices);
  const constraints = getMediaConstraints({
    audio,
    video,
    defaultConstraints: request.getDefaultConstraints()
  });
  logger.debug(
    { request, constraints, devices },
    "Constraints used for getting media"
  );
  try {
    navigator.mediaDevices.dispatchEvent(new Event("devicechange"));
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    navigator.mediaDevices.dispatchEvent(new Event("devicechange"));
    return stream;
  } catch (error) {
    if (error instanceof Error) {
      const devices2 = await getDevices();
      throw new Error(
        normalizeGetUserMediaError(error, constraints, devices2)
      );
    }
    throw error;
  }
};

// src/streams.ts
var handleMediaStream = ({
  dispatch,
  getDefaultConstraints,
  streamTrackMap
}) => {
  const setupMediaStreamTracks = ({ stream }) => {
    let userFacingMode = false;
    for (const track of stream.getTracks()) {
      const { facingMode } = track.getSettings();
      if (track) {
        streamTrackMap.add(track);
      }
      if (track.kind === "video" && isBoolean(facingMode)) {
        userFacingMode = facingMode;
      }
      const mutedListener = () => {
        dispatch({ id: track.id, type: "mute" /* Mute */ });
      };
      track.addEventListener("mute" /* Mute */, mutedListener, false);
      const unmutedListener = () => {
        dispatch({ id: track.id, type: "unmute" /* Unmute */ });
      };
      track.addEventListener(
        "unmute" /* Unmute */,
        unmutedListener,
        false
      );
      const endedListener = () => {
        dispatch({ id: track.id, type: "ended" /* Ended */ });
        track.removeEventListener(
          "ended" /* Ended */,
          endedListener,
          false
        );
        track.removeEventListener(
          "mute" /* Mute */,
          mutedListener,
          false
        );
        track.removeEventListener(
          "unmute" /* Unmute */,
          unmutedListener,
          false
        );
        if (streamTrackMap.has(track)) {
          streamTrackMap.remove(track);
        }
      };
      track.addEventListener("ended", endedListener, true);
    }
    dispatch({
      facingMode: userFacingMode,
      stream,
      type: "stream" /* Stream */,
      video: stream.getVideoTracks().length > 0
    });
  };
  const getUserMedia2 = async ({
    audio,
    video
  }) => {
    try {
      const stream = await getMediaStream({
        audio,
        video,
        getDefaultConstraints
      });
      setupMediaStreamTracks({ stream });
      return stream;
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: "error" /* Error */, error });
      }
      throw error;
    }
  };
  return { getUserMedia: getUserMedia2 };
};
var createStreamTrackEventSubscriptions = (track, handlers) => {
  const trackSubscriptions = Object.keys(handlers).flatMap((eventKey) => {
    const key = eventKey;
    const trackEventHandler = handlers[key];
    if (trackEventHandler) {
      const handleEvent = () => trackEventHandler(track);
      track.addEventListener(key, handleEvent);
      const removeTrackEventHandler = () => {
        track.removeEventListener(key, handleEvent);
      };
      return [removeTrackEventHandler];
    }
    return [];
  });
  return () => {
    trackSubscriptions.forEach((unsubscribeEvent) => unsubscribeEvent());
  };
};

// src/streamTrackMap.ts
var createStreamTrackMap = (tracks) => {
  const streamTrackMap = new Map(
    tracks?.map((track) => [toKey(track), track])
  );
  const findTrack = (device) => {
    return [...streamTrackMap].map(([_, track]) => track).find((track) => {
      const { kind } = track;
      const { deviceId } = track.getSettings();
      if (!deviceId) {
        return false;
      }
      return device.kind.includes(kind) && device.deviceId === deviceId;
    });
  };
  const toStreamTrackKey = (deviceOrTrack) => {
    const track = isMediaStreamTrack(deviceOrTrack) ? deviceOrTrack : findTrack(deviceOrTrack);
    if (!track?.kind || !track.id) {
      return "";
    }
    return toKey(track);
  };
  const add = (track) => {
    const key = toStreamTrackKey(track);
    if (key) {
      return streamTrackMap.set(key, track);
    }
    throw new Error("StreamTrackNotFound" /* StreamTrackNotFound */);
  };
  const has = (deviceOrTrack) => {
    const key = toStreamTrackKey(deviceOrTrack);
    return key && streamTrackMap.has(key);
  };
  const clear = () => {
    streamTrackMap.forEach((track) => {
      track.stop();
    });
    streamTrackMap.clear();
  };
  const remove = (track) => {
    const key = toStreamTrackKey(track);
    track.stop();
    return streamTrackMap.delete(key);
  };
  const size = () => streamTrackMap.size;
  return {
    add,
    has,
    clear,
    remove,
    size
  };
};

// src/index.ts
var state = () => {
  const { dispatch, subscriber } = eventEmitter();
  const streamTrackMap = createStreamTrackMap();
  const { getDefaultConstraints, setDefaultConstraints: setDefaultConstraints2 } = getConstraintsHandlers();
  const { getUserMedia: getUserMedia2 } = handleMediaStream({
    dispatch,
    getDefaultConstraints,
    streamTrackMap
  });
  const subscribe2 = (listener) => {
    return subscriber(
      listener,
      deviceChanged((event) => {
        dispatch({
          type: "devices:changed" /* DevicesChanged */,
          devices: event.devices
        });
        if (!hasAudioOrVideoInputs(event.devices)) {
          return dispatch({
            type: "devices:noinput" /* NoInputDevices */,
            devices: event.devices
          });
        }
        if (event.unauthorized.length) {
          dispatch({
            type: "devices:unauthorized" /* DevicesUnauthorized */,
            devices: event.unauthorized,
            authorizedDevices: event.authorized
          });
        }
        if (event.found.length) {
          dispatch({
            type: "devices:found" /* DevicesFound */,
            authorizedDevices: event.authorized,
            unauthorizedDevices: event.unauthorized,
            devices: event.found
          });
        }
        if (event.lost.length) {
          dispatch({
            type: "devices:lost" /* DevicesLost */,
            authorizedDevices: event.authorized,
            unauthorizedDevices: event.unauthorized,
            devices: event.lost
          });
          for (const device of event.lost) {
            if (streamTrackMap.has(device)) {
              dispatch({ type: "device:lost" /* DeviceLost */, device });
            }
          }
        }
      })
    );
  };
  return {
    getUserMedia: getUserMedia2,
    setDefaultConstraints: setDefaultConstraints2,
    subscribe: subscribe2
  };
};
var {
  getUserMedia,
  setDefaultConstraints,
  subscribe
} = state();
export {
  MediaDeviceFailure,
  MediaDeviceKinds,
  MediaEventType,
  applyConstraints,
  areTracksEnabled,
  compareDevices,
  createStreamTrackEventSubscriptions,
  createTrackDevicesChanges,
  deviceChanged,
  extractConstraintsWithKeys,
  findAudioInputDevices,
  findAudioOutputDevices,
  findCurrentAudioOutputId,
  findCurrentVideoInputDeviceIdFromStream,
  findDevice,
  findDeviceFromConstraints,
  findDeviceWithDeviceId,
  findDevicesByKind,
  findMediaInputFromMediaStreamTrack,
  findMediaInputFromStream,
  findPermissionGrantedDevices,
  findVideoInputDevices,
  getDevices,
  getFacingModeFromConstraintString,
  getInputDevicePermissionState,
  getUserMedia,
  getValueFromConstrainNumber,
  hasAudioInputs,
  hasAudioOrVideoInputs,
  hasChangedInput,
  hasRequestingDevice,
  hasVideoInputs,
  isExactDeviceConstraint,
  isFacingMode,
  isMediaDeviceInfo,
  isMediaDeviceInfoArray,
  isMediaStreamTrack,
  isRequestedInputDevice,
  isRequestedInputTrack,
  isRequestedResolution,
  isStreamingRequestedDevices,
  isStreamingRequestedDevicesBase,
  mergeConstraints,
  muteStreamTrack,
  relaxInputConstraint,
  setDefaultConstraints,
  setLogger,
  shouldRequestDevice,
  stopMediaStream,
  subscribe,
  toKey,
  toMediaDeviceInfoLike
};
